import React, { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from '../../Footer';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import { ModalBox } from '../Outer/Modal';
import Table from 'react-bootstrap/Table';
import { CloseRounded } from '@mui/icons-material';
import { GetWinnerAuction, RefreshToken, SecondaryMarketAuction, SecondaryMarketBidSell, SecondaryMarketAnnouncement } from "../../redux/auth-reducer/auth-service";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as icons from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import { Card, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ToastContainer, toast } from 'react-toastify';
import { DatePicker } from 'rsuite';
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import * as signalR from "@microsoft/signalr";

export const Secondary_Market = () => {
    const [data, setData] = useState(false);
    const [auctionWinner, setAuctionWinner] = useState([])
    const [auctionToSellData, setAuctionToSellData] = useState("")
    const [startingBidToSell, setStartingBidToSell] = useState("")
    const [bidIncrementToSell, setBidIncrementToSell] = useState("")
    const [showView, setShowView] = useState(false);
    const [hubConnection, setHubConnection] = useState(null);
    const [signalRData, setSignalRData] = useState([]);
    const [validated, setValidated] = useState(false);
    const [searchTextLive, setSearchTextLive] = useState();
    const [searchTextClose, setSearchTextClose] = useState("");
    const [searchTextUpcomingLive, setSearchTextUpcomingLive] = useState("");
    const [BidStatusAuction, setBidStatusAuction] = useState([]);
    const [filterClosedAuction, setFilterClosedAuction] = useState([]);
    const [filterUpcomingLiveAuction, setFilterUpcomingLiveAuction] = useState([]);

    const [activeTab, setActiveTab] = useState('profile');
    const navigate = useNavigate();
    const [regForm, setRegForm] = useState({
        startingBid: 0,
        ownerShipSold: 0,
        startDate: ""
    })
    const handleClose = () => {
        setShowView(false)

    };

    const handleShowView = (row) => {

        setShowView(true);
        setAuctionToSellData(row)
    }

    const handlebidIncrementToSell = async (e) => {
        e.preventDefault();
        setBidIncrementToSell(e.target.value)
    };

    const sidebarfun = () => {
        setData(!data)
    }
    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser.userId;

    const handleSell = async (event) => {

       

        event.preventDefault();

        setValidated(true);

        const dataList = [{
            "crudOperationId": true,
            "id": 0,
            "auctionId": auctionToSellData?.auctionId,
            "teamId": auctionToSellData?.teamId,
            "teamOwnerId": auctionToSellData?.teamOwnerId,
            "bidAmount": auctionToSellData?.bidAmount,
            "startingBid": Number(regForm?.startingBid),
            "bidIncrement": auctionToSellData?.bidIncrement,
            "startDate": regForm?.startDate,
            "maxOwnerShip": auctionToSellData?.maxOwnerShip,
            "ownerShipSold": Number(regForm?.ownerShipSold),
        }]

        await SecondaryMarketBidSell(dataList).then((res) => {

            if (res?.data?.status == true) {
                setShowView(false);
                toast.success(res?.data?.message);
               
                setTimeout(() => {
                    window.location.reload();
                  }, 2000)
            
            }
            else {
                toast.error(res?.data?.message)
            }
        })

        setAuctionToSellData('');
        setStartingBidToSell('');
        setBidIncrementToSell('')
    }

    useEffect(() => {

        const GetSecoderyAuction = async () => {
            await SecondaryMarketAuction().then((response) => {
                setAuctionWinner(response?.data?.data?.dataList)

            })
        }
        GetSecoderyAuction();

    }, [])

    const handelForm = (e) => {
        setRegForm({
            ...regForm,
            [e.target.name]: e.target.value
        }
        )
    }

    const handelDate = (e) => {

        setRegForm({
            ...regForm,
            startDate: e,
        },)
    }

    const btnplacebid = (item) => {
        navigate(`/Player/SecodaryPlaceBid?auctionId`, { state: item });
    }
    const convertUTCToLocalTime = (utcDateString) => {
        const date = new Date(utcDateString);
        return date.toLocaleString();
    };

    const column = [
        {
            name: 'ID',
            selector: (row, index) => index + 1,

        },

        {
            name: 'Auction Name',
            selector: (row) => row.auctionName,
            sortable: true,
        },
        {
            name: 'Team Name',
            selector: (row) => row.teamName,
            sortable: true,
        },

        {
            name: 'Bid Amount',
            selector: (row) => row.bidAmount,
            sortable: true,
        },
        {
            name: 'Max Ownership %',
            selector: (row) => row.maxOwnerShip,
            sortable: true,
        },
        {
            name: 'Bid Increment',
            selector: (row) => row.bidIncrement,
            sortable: true,
        },

        {
            name: 'Action',
            selector: (row) =>
                
                
                <Button  onClick={() => handleShowView(row)} >Sell</Button>,
            sortable: true,
        },
    ];

    const handleSearchLive = (e) => {
        setSearchTextLive(e.target.value);
    };

    const filteredDataAuctionWinner = searchTextLive ? auctionWinner.filter((item) => {

        return item.status.toLowerCase() === 'live' && item?.auctionName?.toLowerCase().includes(searchTextLive.toLowerCase());
    }) : auctionWinner?.filter((item) => item?.status?.toLowerCase() === 'live');

    useEffect(() => {
        const createHubConnection = async () => {
            let token = localStorage.getItem('isToken');
            const hubConnect = new signalR.HubConnectionBuilder()
                .withUrl("https://staging.xcutta.com/backend/hubs", {
                    // https://gadienterprises-gamc-qa.chetu.com/hubs
                    // https://staging.xcutta.com/backend/hubs
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => token,
                })
                .withAutomaticReconnect()
                .build();

            const handleTokenExpiration = async () => {
                const newToken = await RefreshToken();
                token = newToken?.data?.token;
                hubConnect.accessTokenFactory = () => token;
            };
            try {
                await hubConnect.start();
            } catch (err) {
                await handleTokenExpiration();
                await hubConnect.start();
            }

            setHubConnection(hubConnect);
        };

        createHubConnection();
    }, []);

    useEffect(() => {

        if (hubConnection) {
            hubConnection.on("ReceiveSMBidStatuses", (data) => {
                setSignalRData(data);
                console.log(data, "dataaaaaa");
            });
        }
        else {

        }
    }, [hubConnection]);

    const columns = [

        {
            name: "Team Name",
            selector: (row) => row.teamName,
            sortable: true,
        },
        {
            name: "Auction Name",
            selector: (row) => row.auctionName,
            sortable: true,
        },
        {
            name: "Tournament Name",
            selector: (row) => row.tournamentName,
            sortable: true,
        },
        {
            name: "Sport Name",
            selector: (row) => row.sportsName,
            sortable: true,
        },

        {
            name: "Start Date",
            selector: (row) => dayjs(row.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: <strong>Start Time</strong>,

            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: "Bid Time Limit",
            selector: (row) => row.bidTime,
            sortable: true,
        },

        {
            name: "Starting Bid",
            selector: (row) => row.startingBid,
            sortable: true,
        },
        {
            name: "Bid Increment",
            selector: (row) => row.bidIncrement,
            sortable: true,
        },

        {
            name: "Bid Status",
            selector: (row) =>
                row.status === "Upcoming" ? (
                    <>
                        <span style={{ color: "#f9c114" }}>Upcoming</span>
                    </>
                ) : row.status === "Live" ? (
                    <>
                        <span style={{ color: "#043bc7" }}>Live</span>
                    </>
                ) : row.status === "Closed" ? (
                    <>
                        <span style={{ color: "red" }}>Closed</span>
                    </>
                ) : row.status === "Paused" ? (
                    <>
                        <span style={{ color: "red" }}>Paused</span>
                    </>
                ) : row.status === "Archive" ? (
                    <>
                        <span style={{ color: "red" }}>Closed</span>
                    </>
                ) : (
                    <>
                        <span style={{ color: "green" }}>Play</span>
                    </>
                ),

            sortable: true,
        },
        {
            name: "Created By",
            selector: (row) => row.createdByName,
            sortable: true,
        },
        {
            name: "",
            selector: (row) => (
                <>
                    {row.status === "Live" ? <button
                        className="tablebtn placebid"

                        onClick={() => btnplacebid(row)}
                    >
                        Place Bid
                    </button> :
                        <button
                            className="tablebtn placebid disable"
                            disabled

                            onClick={() => btnplacebid(row)}
                        >
                            Place Bid
                        </button>
                    }

                </>
            ),
            sortable: true,
        },

    ];

    const Columns_SM_Winners = [

        {
            name: "Team Name",
            selector: (row) => row.teamName,
            sortable: true,
        },
        {
            name: "Auction Name",
            selector: (row) => row.auctionName,
            sortable: true,
        },

        {
            name: "Start Date",
            selector: (row) => dayjs(row.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: "Start Time",

            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: "End Date",
            selector: (row) => dayjs(row.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: "End Time",

            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: "Bid Amount",
            selector: (row) => row.bidTime,
            sortable: true,
        },

        {
            name: "Bid Status",
            selector: (row) =>
                row.status === "Upcoming" ? (
                    <>
                        <span style={{ color: "#f9c114" }}>Upcoming</span>
                    </>
                ) : row.status === "Live" ? (
                    <>
                        <span style={{ color: "#043bc7" }}>Live</span>
                    </>
                ) : row.status === "Closed" ? (
                    <>
                        <span style={{ color: "red" }}>Closed</span>
                    </>
                ) : row.status === "Paused" ? (
                    <>
                        <span style={{ color: "red" }}>Paused</span>
                    </>
                ) : (
                    <>
                        <span style={{ color: "green" }}>Play</span>
                    </>
                ),

            sortable: true,
        },

    ];

    const handleSearchClose = (e) => {
        setSearchTextClose(e.target.value);
    };
    const handleSearchUpcomingLive = (e) => {
        setSearchTextUpcomingLive(e.target.value);
    };

    const filteredDataUpcomingLiveAuction = searchTextUpcomingLive ? filterUpcomingLiveAuction.filter((item) => {
        return item?.auctionName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.sportsName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.status.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase())

    }) : filterUpcomingLiveAuction;

    const filteredDataCloseAuction = searchTextClose ? filterClosedAuction.filter((item) => {
        return item?.auctionName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item.sportsName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextClose.toLowerCase()) || item?.status?.toLowerCase().includes(searchTextClose?.toLowerCase())
    }) : filterClosedAuction;

    // const filteredDataSMWinners = searchTextClose ? filterClosedAuction.filter((item) => {
    //     return item?.auctionName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item.sportsName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextClose.toLowerCase()) || item?.status?.toLowerCase().includes(searchTextClose?.toLowerCase())
    // }) : filterClosedAuction;

    useEffect(() => {
        const filterUpcomingsLivesAuction = (eventArray) => {
            return eventArray?.filter((event) => event.status === "Upcoming" || event.status === "Live");
        };

        setFilterUpcomingLiveAuction(filterUpcomingsLivesAuction(BidStatusAuction));
        console.log(filterUpcomingsLivesAuction(BidStatusAuction), "filterUpcomingsLivesAuction(BidStatusAuction)");

        const filterClosesAuction = (eventArray) => {
            return eventArray?.filter((event) => event.status === "Closed" || event.status === "Archive");
        };
        setFilterClosedAuction(filterClosesAuction(BidStatusAuction));
    }, [signalRData, BidStatusAuction]);

    console.log(filterUpcomingLiveAuction, "filterUpcomingLiveAuction");

    useEffect(() => {
        if (signalRData && BidStatusAuction) {
            const updatedAuctionData = BidStatusAuction.map((auction) => {
                const matchingSignalRAuction = signalRData.find((signalRAuction) => signalRAuction.auctionId === auction.auctionId && signalRAuction.teamId === auction.teamId);
                console.log(matchingSignalRAuction, "matchingSignalRAuction");
                if (matchingSignalRAuction) {
                    auction.currentBidAmount = matchingSignalRAuction.currentBidAmount;
                    auction.startingBid = matchingSignalRAuction.startingBid;
                    auction.status = matchingSignalRAuction.status;
                    auction.remainingBidTime = matchingSignalRAuction.remainingBidTime;
                    auction.totalPlacedBids = matchingSignalRAuction.totalPlacedBids;

                }
                return auction;
            });
            console.log(updatedAuctionData, "updatedAuctionData");
            setBidStatusAuction(updatedAuctionData);
        }
    }, [signalRData]);

    console.log(signalRData, "signalRData");
    console.log(BidStatusAuction, "BidStatusAuction");

    useEffect(() => {
        const BidStatusAll = async () => {
            await GetWinnerAuction().then((res) => {
                const sortedData = res?.data?.data?.dataList.sort((a, b) => new Date(b.date) - new Date(a.date));
                sortedData.reverse();
                setBidStatusAuction([...sortedData]);
            });
        }
        BidStatusAll()
    }, [activeTab])




    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
      };

    return (
        <>
            <ToastContainer />
            <TopNavbar sidebarfun={sidebarfun} />
            <Modal size="lg" show={showView} onHide={handleClose}>
                <Form noValidate validated={validated}   >
                    <Modal.Header >
                        <Modal.Title>Resale Bid</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustom02">
                                <Form.Label>Auction Name</Form.Label>
                                <Form.Control name="firstName" disabled value={auctionToSellData?.auctionName} required type="text" placeholder="Enter your first name" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter First Name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Team Name</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="teamName"
                                        disabled
                                        placeholder=" Enter your last name"
                                        value={auctionToSellData?.teamName}
                                        aria-describedby="inputGroupPrepend"
                                        readOnly
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Last Winning Amount</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        name="bidAmount"
                                        placeholder=" Enter your last name"
                                        value={auctionToSellData?.bidAmount}
                                        aria-describedby="inputGroupPrepend"
                                        readOnly
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Current Ownership %</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        name="lastName"
                                        placeholder=" Enter your last name"
                                        value={auctionToSellData?.maxOwnerShip}
                                        aria-describedby="inputGroupPrepend"
                                        readOnly
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Bid Increment </Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        disabled
                                        placeholder=" Enter Bid Increment for Sell"
                                        value={auctionToSellData?.bidIncrement}
                                        aria-describedby="inputGroupPrepend"
                                        onChange={(e) => handlebidIncrementToSell(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a bid increment.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Sell Ownership %</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select onChange={handelForm} name="ownerShipSold" className='select-option' required as="select">
                                        <option value={""} >Select Ownership</option>
                                        {
                                            auctionToSellData?.maxOwnerShip == 25 ? (<>
                                                <option value={"25"}>25%</option>

                                            </>) : auctionToSellData?.maxOwnerShip == 50 ? (<>
                                                <option value={"25"}>25%</option>
                                                <option value={"50"} >50%</option>

                                            </>) : (<>
                                                <option value={"25"}>25%</option>
                                                <option value={"50"} >50%</option>
                                                <option value={"100"} >100%</option>

                                            </>)
                                        }

                                    </Form.Select >
                                    <Form.Control.Feedback type="invalid">
                                        Please select a ownership sold.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Minimum Bid</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="startingBid"
                                        placeholder=" Enter minimum bid to sell"
                                        required
                                        aria-describedby="inputGroupPrepend"
                                        onChange={handelForm}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a minimum bid.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md={6} className="mt-3">
                                <Form.Label>Start Bid Date and Time</Form.Label>
                                <DatePicker format="MM/dd/yyyy hh:mm aa" placeholder="Start Date"
                                    disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)}
                                    name="startDate" className=' Auction-Date'
                                    onChange={handelDate} required />

                                <Form.Control.Feedback type="invalid">
                                    Please select the date and time.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSell}>
                            Bid Sell
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Secondary Market</h1>
                   <div className='group_data_table'>

                        <div className="group_data_table tabs-auction">
                            <div className="tabs-box">
                                <Tabs
                                    defaultActiveKey="Sell Auctions"
                                    id="auctiontabs"
                                    onSelect={handleTabChange}
                                    className="mb-0 tab-list-item"
                                    justify
                                >
                                    <Tab eventKey="Sell Auctions" title="Sell Auctions">
                                        <div className='search-input filter d-flex align-items-center'>
                                            <label className='me-3'>Search</label>
                                            <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchTextLive} onChange={handleSearchLive} />
                                        </div>
                                        <DataTable
                                            title="dfsfd"
                                            columns={column}
                                            data={filteredDataAuctionWinner}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            paginationTotalRows={filteredDataAuctionWinner?.length}
                                        />

                                    </Tab>

                                    <Tab eventKey="Live Auction" title="Live and Upcoming">
                                        <div>

                                            <div className="search-input filter auction-filter palyer">

                                                <Form.Group as={Col} md="6 mt-3 me-3" className="text-left  mt-2" controlId="validationCustomOption">
                                                    <Form.Label className="search-player-auction">Search</Form.Label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3 mt-3"
                                                        placeholder="Search..."
                                                        value={searchTextUpcomingLive}
                                                        onChange={handleSearchUpcomingLive}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <DataTable
                                                columns={columns}
                                                data={filteredDataUpcomingLiveAuction}
                                                pagination
                                                paginationPerPage={10}
                                                defaultSortFieldId={1}
                                                paginationRowsPerPageOptions={[10, 20, 30]}
                                            />

                                        </div>
                                    </Tab>

                                    

                                    <Tab eventKey="Closed" title="Closed">
                                        <div>
                                            <div className="auctiontabs">
                                                <div className="search-input filter auction-filter">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3 mt-3"
                                                        placeholder="Search..."
                                                        value={searchTextClose}
                                                        onChange={handleSearchClose}
                                                    />
                                                </div>

                                                < DataTable
                                                    columns={columns}
                                                    data={filteredDataCloseAuction}
                                                    pagination
                                                    paginationPerPage={10}
                                                    defaultSortFieldId={1}
                                                    paginationRowsPerPageOptions={[10, 20, 30]}
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={data ? "Footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}

