
import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import * as icons from 'react-bootstrap-icons';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { AuctionGetAll, AuctionDelete, AuctionUpdate, SportAllMap,  TeamGetAll, TournamentAll, UploadImage, WinCount, AuctionPlayPause } from '../../redux/auth-reducer/auth-service';
import { Footer } from '../../Footer';
import { ModalBox, ModalDelete } from '../Outer/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl } from '../../utils/config/config';
import { DatePicker } from 'rsuite';
import dayjs from 'dayjs';

export const Auction = () => {
  const [data, setData] = useState();
  const [itemList, setItemList] = useState('');
  const [searchText, setSearchText] = useState('');
  const [itemSport, setItemSport] = useState([])
  const [count, setCount] = useState(0);
  const [deleteRow, setDeleteRow] = useState(0);
  const [showEdit, setShowEdit] = useState();
  const [validated, setValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageName, setImageName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [tounamentItem, setTounamentItem] = useState([]);
  const [pause, setPause] = useState(false);
  const [playStatus, setPlayStatus] = useState({});

  const [auctionImage, setAuctionImage] = useState();
  const [teamFkID, setTeamFkID] = useState([]);
  const [dataImage, setDataImage] = useState();
  const [itemTeam, setItemTeam] = useState();
  const [selectedTournament, setSelectedTournament] = useState();
  const [secondryMarketStatus, setSecondryMarketStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [number, setNumber] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const [auctionListWinner, setAuctionListWinner] = useState([])
  const [fnDisabled, setFnDisabled] = useState(true);
  const [fields, setFields] = useState([]);
  const [counter, setCounter] = useState(0);
  const [allXcuttaPercentage, setAllXcuttaPercentage] = useState();
  const [winningPlaceInptData, setWinningPlaceInptData] = useState([]);
  const [sportIdSelect, setSportIdSelect] = useState()
  const [errorMessageXcutta, setErrorMessageXcutta] = useState(false);
  const [winingPlace, setWiningPlace] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [filterUpcoming, setFilterUpcoming] = useState([]);
  const [filterLive, setFilterLive] = useState([]);
  const [filterClosed, setFilterClosed] = useState([]);
  const [filterPaused, setFilterPaused] = useState([]);
  const [editData, setEditData] = useState({
    auctionId: 0,
    auctionName: "",
    auctionImage: "",
    tournamentId: 0,
    sportId: 0,
    teamId: 0,
    startDate: "",
    bidTime: 0,
    winnerCount: 0,
    winnerTitle: [],
    winnerPercentage: [],
    xcuttaTake: 0,
    secondaryMarket: true
  });

  function sidebarfun() {
    setData(!data)
  }

  useEffect(() => {

    const SportAllFn = async () => {
      await SportAllMap().then((response) => {
        
        setItemSport(response?.data?.data?.dataList)
      })
    }
    SportAllFn();

  }, [])



  const convertUTCToLocalTime = (utcDateString) => {
    const date = new Date(utcDateString);
    return date.toLocaleString(); 
  };

  const columns = [
    {
      name: <strong>Id</strong>,
      selector: (row, index) => index + 1,
    
    },

    {
      name: <strong>Auction Name</strong>,
      selector: (row) => row?.auctionName,
      sortable: true,
    },
    {
      name: <strong>Tournament Name</strong>,
      selector: (row) => row?.tournamentName,
      sortable: true,
    },
    {
      name: <strong>Sport Name</strong>,
      selector: (row) =>
        itemSport?.map((value) => {
          if (value.sportsId == row?.sportId) {
            return value.sportsName;
          }
        }),
      sortable: true,
    },

    {
      name: <strong>Start Date</strong>,
      selector: (row) => dayjs(row?.startDate).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: <strong>Start Time</strong>,

      selector: (row) => {
        const localTime = convertUTCToLocalTime(row?.startDate + "Z");
        const [datePart, timePart] = localTime.split(", ");
        return (
          <p>{timePart}</p>
        );
      },
     
    },

    {
      name: <strong>Bid Time Limit</strong>,
      selector: (row) => row?.bidTime,
      sortable: true,
    },
    {
      name: <strong>Secondary Market</strong>,
      selector: (row) =>
        row?.secondaryMarket == true ? (
          <div className="bg-succ">Available</div>
        ) : (
          <div className="bg-dang">Unavailable</div>
        ),
      sortable: true,
    },
    {
      name: <strong>Bid Status</strong>,
     
      selector: (row) =>
        row?.status === "Upcoming" ? (
          <>
            <span style={{ color: "#f9c114" }}>Upcoming</span>
          </>
        ) : row.status === "Live" ? (
          <>
            <span style={{ color: "#043bc7" }}>Live</span>
          </>
        ) : row.status === "Paused" ? (
          <>
            <span style={{ color: "red" }}>Paused</span>
          </>
        ) : (
          <span style={{ color: "red" }}>Closed</span>
        ),
      sortable: true,
    },
    {
      name: <strong>Action</strong>,
      selector: (row) => (
        <>
          <button
            type="button"
            className="text-danger btn-aucion"
            onClick={() => deleteFn(row?.auctionId)}
          >
            <icons.Trash />
          </button>
          <button
            type="button"
            className="text-success btn-aucion"
            onClick={() => editFn(row)}
          >
            <icons.PencilSquare />
          </button>
          {row.status === "Paused" ? (
            <button
              type="button"
              className="text-success btn-aucion"
              onClick={() => playPausefN(row?.auctionId)}
            >
              <icons.Play style={{ border: "2px solid" }} />

            </button>
          ) : (
            <button
              type="button"
              className="text-success btn-aucion"
              onClick={() => playPausefN(row?.auctionId)}
            >
              <icons.Pause style={{ border: "2px solid" }} />
            </button>
          )}
        </>
      ),
      sortable: true,
    }

  ];



  const updatePlayStatus = (auctionId, status) => {
    setPlayStatus(prevStatus => ({
      ...prevStatus,
      [auctionId]: status,
    }));
    setPause(!pause)
  };



  const playPausefN = (auctionId) => {
    updatePlayStatus(auctionId, !playStatus[auctionId]);
    const AuctionPlayPauseStatus = async () => {
      await AuctionPlayPause(auctionId, !playStatus[auctionId]).then((response) => {
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
      });
    };
    AuctionPlayPauseStatus();
  };


  const handleUpdate = async () => {

    setValidated(true);
    if (editData?.winnerCount) {

    }

    let totalPer = winningPlaceInptData.reduce((acc, item) => {
      item.input.map((elem) => {
        acc += Number(elem.winnerPercentage)
      })
      return acc
    }, 0)

    let winnerPercentage = winningPlaceInptData.reduce((acc, item) => {
      item.input.map((elem) => {
        acc.push(Number(elem.winnerPercentage))
      })
      return acc
    }, [])

    let winnertitle = winningPlaceInptData.reduce((acc, item) => {
      item.input.map((elem) => {
        acc.push((elem.name))
      })
      return acc;
    }, [])

    totalPer += Number(editData?.xcuttaTake)




    if (editData?.winnerCount) {
      
      if (totalPer == 100) {
       
      } else {
        
        return setErrorMessage(true);
      }

    }

    const updateDate = auctionImage ? {
      "crudOperationId": false,
      "teamId": editData?.teamId,
      "auctionId": editData?.auctionId,
      "auctionName": editData?.auctionName,
      "auctionImage": dataImage,
      "tournamentId": editData?.tournamentId,
      "sportId": parseInt(sportIdSelect),
      "teamId": teamFkID,
      "startDate": editData?.startDate,
      "xcuttaTake": editData?.xcuttaTake,
      "bidTime": editData?.bidTime,
      "startingBid": editData?.startingBid,
      "bidIncrement": editData?.bidIncrement,
      "winnerCount": editData?.winnerCount,
      "winnerTitle": winnertitle,
      "winnerPercentage": winnerPercentage,
      "secondaryMarket": secondryMarketStatus,
    } : {
      "crudOperationId": false,
      "teamId": editData?.teamId,
      "auctionId": editData?.auctionId,
      "auctionName": editData?.auctionName,
      "auctionImage": editData?.auctionImage,
      "tournamentId": editData?.tournamentId,
      "sportId": parseInt(sportIdSelect),
      "teamId": teamFkID,
      "startingBid": editData?.startingBid,
      "bidIncrement": editData?.bidIncrement,
      "startDate": editData?.startDate,
      "xcuttaTake": editData?.xcuttaTake,
      "bidTime": editData?.bidTime,
      "winnerCount": editData?.winnerCount,
      "winnerTitle": winnertitle,
      "winnerPercentage": winnerPercentage,
      "secondaryMarket": secondryMarketStatus,
    }

    await AuctionUpdate(updateDate).then((res) => {
    
      if (res?.status == true) {
        toast.success(res?.message);
        setShowEdit(false);
        setCount(count + 1);
      } else {
        toast.error(res?.message);
      }
    })

  }

  const editFn = (row, e) => {
   
    const event = (`${row?.sportId} - ${row.teamId} = ${row?.tournamentId}`)
    const [itemList, array, value] = event.split(/[-=]/)
    setTeamFkID(array.split(",").map((e) => parseInt(e)))

  
    setSelectedTournament(value)
    setSportIdSelect(itemList);
    setShowEdit(true);
    var valuesWinner = row?.winnerPercentage?.split(",")
    var finalData = { ...row, winnerPercentage: valuesWinner };
    setEditData(finalData)
  
    setNumber(row?.winnerCount)
  

  }

  useEffect(() => {
 
    const data = []

  

    winingPlace?.map((item, index) => {
      if (index <= editData?.winnerCount - 1) {
     
        data.push({
          name: item.level, input: Array(item.winnerCounts).fill(1).map((inp, ind) => ({
            name: `Winner Place ${ind + 1}`, value: ``,
            winnerPercentage: editData?.winnerPercentage[index * item.winnerCounts + ind]
          }))
        })
      }
    })

    setWinningPlaceInptData(data);
  }, [winingPlace])



  useEffect(() => {
    if (editData?.startDate) {
      setStartDate(new Date(editData?.startDate));
    }
  }, [editData]);



  useEffect(() => {
    if (editData?.tournamentId) {
      setTournamentId(new Date(editData?.tournamentId));
    }
  }, [editData]);


  const handelEditSport = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value })
  }
  const deleteFn = (auctionId) => {
    setShowDeleteModal(true);
    setDeleteRow(auctionId)

  }
  const handleClosed = () => {
    setShowDeleteModal(false);
    setShowEdit(false);
  };

  const handelDelete = async () => {
    await AuctionDelete(deleteRow).then((res) => {
      if (res?.status == true) {
        setCount(count + 1);
        toast.success(res?.message);
     
        setShowDeleteModal(!showDeleteModal);
      } else {
        toast.error(res?.message)
      }
    })
  }

  useEffect(() => {

    const AuctionAllFn = async () => {
      await AuctionGetAll().then((response) => {
  
        setItemList(response?.data?.data?.dataList);

        setAuctionListWinner(response?.winnerPercentage?.split(",").map(Number));
      })
    }
    AuctionAllFn();

  }, [count]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handelFrom = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    }
    )
  }
  const handelAuctionTake = (e) => {
    setAllXcuttaPercentage(100 - e.target.value)

    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    },)
    setErrorMessage(false)
    setErrorMessageXcutta(false);
  }

  const handelDate = async (date) => {
    setEditData({
      ...editData,
      startDate: date,
    },)

  };

  const handelImage = (e) => {

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      setAuctionImage(e.target.files[0].name)
      reader.readAsDataURL(e.target.files[0]);
    }
    setSelectedFile(e.target.files[0].name)
    setImageName(e.target.files[0].name)
    const dataList = (e.target.files[0]);
    const imageAPI = async () => {
      const formDataImage = new FormData();
      formDataImage.append('file', dataList);
      await UploadImage(formDataImage).then((res) => {
        if (res?.data?.status == true) {
          setDataImage(res?.data?.data);
         

        } else {

        }
      })
    }

    imageAPI()
  }

  useEffect(() => {

    const TeamAllFn = async () => {
      await TeamGetAll().then((response) => {
        setItemTeam(response?.data?.data?.dataList);
      })
    }
    TeamAllFn();

  }, [])

  useEffect(() => {

    const TournamentAllFn = async () => {
      await TournamentAll().then((response) => {

        setTounamentItem(response?.data?.data?.dataList);

      })
    }
    TournamentAllFn();

  }, [])


  const handleSelectedTurnament = (event) => {

    const [itemList, array, value] = event.target.value.split(/[-=]/)
    setSportIdSelect(itemList);
    setTeamFkID(array.split(",").map((e) => parseInt(e)))
    setSelectedTournament(value)
    let teamsLength = array.split(",").map((e) => parseInt(e)).length
    if (teamsLength) {
      const AuctionWiningPlace = async () => {
        await WinCount(teamsLength).then((response) => {
          setWiningPlace(response?.data)
        })
      }
      AuctionWiningPlace();
    }

  }

  useEffect(() => {
    const teamFkIDString = teamFkID.join(', ');
    let teamsLength = teamFkIDString.split(",").map((e) => parseInt(e)).length
    if (teamsLength) {
      const AuctionWiningPlace = async () => {
        await WinCount(teamsLength).then((response) => {
          setWiningPlace(response?.data)
        })
      }
      AuctionWiningPlace();
    }
  }, [selectedTournament]);


  const handelCount = (e) => {
   

    setEditData({
      ...editData,

    },);
    const data = [];
    let currentWinnerPlace = 1;
    winingPlace.map((item, index) => {
      if (index <= e.target.value) {
     
        data.push({ name: item.level, input: Array(item.winnerCounts).fill(1).map((inp, ind) => ({ name: `Place ${currentWinnerPlace++}`, value: `` })) })
      }
    })
   
    setWinningPlaceInptData(data);
  }
  useEffect(() => {
    let data = []
    for (let i = 1; i <= number; i++) {
      let ordinal = i + getOrdinalSuffix(i) + " Place Winner (%)";
      data.push(ordinal);
    }
    setFields(data)

  }, [number])

  let dateString = editData?.dateOfBirth;
  let date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    let localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    var formattedDateOfBirth = localDate.toISOString().split('T')[0];
    let parts = formattedDateOfBirth.split('-');

  } else {
    console.error('Invalid date format');

  }

  const handleChangeOption = (event) => {
    setSelectedOption(event.target.value);
 
  }

  useEffect(() => {
    if (searchText) {
      const filtered = itemList.filter((item) => {
        return (
          item?.auctionName?.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.status?.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.sportsName?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(itemList);
    }
  }, [searchText, itemList]);


  useEffect(() => {
    if (selectedOption === "live") {
      const filtered = filterLive.filter((item) =>
        item.status.toLowerCase().includes("live")
      );
      setFilteredData(filtered);
    } else if (selectedOption === "upcoming") {
      const filtered = filterUpcoming.filter((item) =>
        item.status.toLowerCase().includes("upcoming")
      );
      setFilteredData(filtered);
    } else if (selectedOption === "closed") {
      const filtered = filterClosed.filter((item) =>
        item.status.toLowerCase().includes("closed")
      );
      setFilteredData(filtered);
    } else if (selectedOption === "Paused") {
      const filtered = filterPaused.filter((item) =>
        item.status.toLowerCase().includes("paused")
      );
      setFilteredData(filtered);
    }
    else {
      setFilteredData(itemList);
    }
  }, [selectedOption, itemList]);

  useEffect(() => {
    if (itemList) {
  
      const filterUpcomings = (eventArray) => {
        return eventArray.filter((event) => event.status === "Upcoming");
      };
      setFilterUpcoming(filterUpcomings(itemList));


      const filterLives = (eventArray) => {
        return eventArray.filter((event) => event.status === "Live");
      };
      setFilterLive(filterLives(itemList));

      const filterCloses = (eventArray) => {
        return eventArray.filter((event) => event.status === "Closed");
      };
      setFilterClosed(filterCloses(itemList));

      const filterPauses = (eventArray) => {
        return eventArray.filter((event) => event.status === "Paused");
      };
      setFilterPaused(filterPauses(itemList));
    }
  }, [itemList]);

  const textEdit = (str, index) => {
    setFields((prev) => {
      return prev.map((e, i) => {
        if (i == index) {
          return str
        } else {
          return e
        }
      })
    });

  }
  const handleClick = () => {
    setFnDisabled(false);
    setFnDisabled(!fnDisabled);
  }

  const handleWinningPlaceChange = (levelIndex, placeIndex) => (event) => {
    const { name, value } = event.target
    const copy = [...winningPlaceInptData]
    copy[levelIndex].input[placeIndex][name] = value
    setWinningPlaceInptData(copy);
  }

  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100;
    return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  };

  useEffect(() => {

    editData.winnerCount = winningPlaceInptData.length
  
  }, [winningPlaceInptData])

  return (
    <>
      <ToastContainer />
      <ModalBox show={showEdit} onHide={handleClosed} title="Update Auction" body={
        <>
          <Row>
            <Form.Group as={Col} md={6} controlId="validationCustom02">
              <Form.Label>Auction Name</Form.Label>
              <Form.Control name="auctionName" value={editData?.auctionName} onChange={handelEditSport} className='select-option tournament' required type="text" />
              <Form.Control.Feedback type="invalid">
                Please enter Auction Name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6} className="text-left">
              <Form.Label >Select Tournament  </Form.Label>
              <InputGroup hasValidation>
                <Form.Select name="tournamentId"
               
                  onChange={handleSelectedTurnament}
                  className='select-option tournament' required as="select">
                  <option>{editData?.tournamentName}</option>
                  {
                    tounamentItem?.map((item) => <>
                      <option key={item?.tournamentId}
                        value={`${item?.sportsId} - ${item.teamId} = ${item?.tournamentId}`}
                      >{item?.tournamentName}</option>

                    </>
                    )
                  }
                </Form.Select >
                <Form.Control.Feedback type="invalid">
                  Please select a Tournament.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <p className={`${selectedTournament ? "mt-3 label" : ""}`}> {
              `${selectedTournament ? "Sport Name" : ""}`
            }  </p>
            <Form.Group as={Col} md={12}>
              <p className={`${selectedTournament ? "input-game" : ""}`}>

                {itemSport?.map((value) =>
                  value?.sportsId == sportIdSelect && value?.sportsName
                )}

              </p>

            </Form.Group>

            {selectedTournament ?
              <Form.Group as={Col} md={12} className='team-auction-update'>
                <Table className='table-team border'>
                  <thead>
                    <tr>
                      <th>Team</th>
                      <th>Name</th>

                    </tr>
                  </thead>
                  {

                    itemTeam?.map((listItem) => teamFkID?.map((item) =>
                      item == listItem?.teamId &&

                      <tbody>
                        <tr>
                          <td><img src="../image/image-team.png"></img></td>
                          <td >{listItem?.teamName}</td>
                        </tr>

                      </tbody>

                    ))

                  }
                </Table></Form.Group> : ""}

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Auction Start Date</Form.Label>
              <DatePicker

                format="MM/dd/yyyy hh:mm aa"
                value={startDate}
                name="startDate"
                onChange={handelDate}
                disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter Auction Start Date.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Bid Time Limit</Form.Label>
              <InputGroup hasValidation>
                <Form.Select name="bidTime" value={editData?.bidTime} onChange={handelFrom} className='select-option tournament' required as="select">
                  <option value={1} >1 minutues</option>
                  <option value={2} >2 minutues</option>
                  <option value={3}>3 minutues</option>
                  <option value={4} >4 minutues</option>
                  <option value={5} >5 minutues</option>
                  <option value={6} >6 minutues</option>
                  <option value={7} >7 minutues</option>
                  <option value={8} >8 minutues</option>
                  <option value={9} >9 minutues</option>
                  <option value={10} >10 minutues</option>

                </Form.Select >
                <Form.Control.Feedback type="invalid">
                  Please add Bid Time Limit.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Xcutta take % </Form.Label>
              <Form.Control name="xcuttaTake" value={editData?.xcuttaTake} onChange={handelAuctionTake} className='select-option tournament' required type="number" placeholder="Enter your cut" />
              <Form.Control.Feedback type="invalid">
                Please enter Xcutta take.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Logo image </Form.Label>
              <input type='file' className='form-control' name="auctionImage" onChange={handelImage}  ></input><br />
            </Form.Group>
            <Form.Group as={Col} md={6}></Form.Group>
            {auctionImage ? <img src={selectedImage} className='imageAuction' alt="Selected" width={100} /> : <img src={ImageUrl + editData?.auctionImage} className='imageAuction' width={150}></img>}

            <p className='winner-distribution'>Winner Prize Distribution {100 - editData?.xcuttaTake} %</p>

            {
              allXcuttaPercentage === 0 || allXcuttaPercentage < 0
                ? ''
                : <>

                  <Form.Label>Total Winners </Form.Label>
                  <Form.Select name="winnerCount" onChange={handelCount} className='select-option tournament' required as="select">
             
                 

                    {
                      winingPlace?.map((item, index) => (
                        <option key={index} value={index}> {index + 1} </option>
                      ))
                    }

                  </Form.Select >
                </>
            }

            {
              editData?.winnerCount ? <div className='winner-percentage'>
                <Row>
                  {
                    winningPlaceInptData?.map((item, levelIndex) => {
                      return (
                        <Col md={12}>

                        
                          {item?.input?.map((elem, j) => {
                            return (
                              <div key={`input-${j}`}>
                                <input
                                  type='text'
                                  className='input-editable'
                                  name="name"
                                  value={elem.name}
                                  onChange={handleWinningPlaceChange(levelIndex, j)}
                                  disabled={fnDisabled}
                                />

                                <button
                                  type="button"
                                  className="btn-edit"
                                  onClick={handleClick}
                                >
                                  {fnDisabled ? (
                                    <>
                                      <icons.Pencil className='edit-icon' />
                                    </>
                                  ) : (
                                    'save'
                                  )}
                                </button>
                                <input
                                  name="winnerPercentage"
                                  className='select-option tournament xcuttaTakeinput mb-2'
                                  placeholder='Enter winner Percentage'
                                  type="number"
                                  onChange={handleWinningPlaceChange(levelIndex, j)}
                                  value={elem.winnerPercentage}
                             
                                />
                              </div>
                            )
                          })}

                        </Col>
                      )
                    })
                  }

                </Row>

                {
                  errorMessage ? <p className='err-message'>Please ensure that the total distribution among the winner should be equal {allXcuttaPercentage} %</p> : ""
                }
              </div> : ""
            }
          </Row>

        </>
      }

        footer={
          <>
            <Button variant="primary" className="btn-Save me-3" onClick={handleUpdate}>Update</Button>
            <Button type="button" className='btn-Cancel secondary' onClick={handleClosed} >Cancel</Button>
          </>
        } />

      <ModalDelete show={showDeleteModal} onHide={handleClosed} title="Delete Auction" body={
        <>
          Are you want to delete the auction?

        </>
      } footer={
        <>
          <Button variant="danger" onClick={handelDelete} >Delete</Button>
          <Button variant="secondary" onClick={handleClosed} >Close</Button>

        </>
      } />

      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <Row>
            <Col md={6}><h1 className="heading-dash">Auction</h1></Col>
            <Col md={6} className='text-right'>
              <Link to="/SuperAdmin/CreateAuction"> <Button className='primary button-create-auction'> <icons.Plus class="icon-plus" /> Create New Auction</Button></Link>
            </Col>

          </Row>

          <div className='group_data_table auction-list'>

            <div class="auction-manager search-input filter d-flex align-items-center">

              <label className='sort-status'>Sort By Status</label>

              <Form.Select
                name="select"
                value={selectedOption}
                onChange={handleChangeOption}
                className="me-4"
                required
                as="select"
              >
                <option value={""}>Select Status</option>
                <option value={"live"}>Live</option>
                <option value={"upcoming"}>Upcoming</option>
                <option value={"closed"}>Closed</option>
                <option value={"Paused"}>Paused</option>

              </Form.Select>

              <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />

            </div>

            <DataTable
              title="auction"
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30]}
              paginationTotalRows={filteredData?.length}
            />
          </div>

        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  )
}
