import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { RemovRoles, RemoveRefreshToken, RemoveToken, RemoveUser } from '../../utils/helper/helper';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { ManagerGetById, PlayerGetById } from '../../redux/auth-reducer/auth-service';
import { ImageUrl } from '../../utils/config/config';
import * as signalR from '@microsoft/signalr'
import * as icons from "react-bootstrap-icons";

function TopNavbar({ sidebarfun }) {

    const roles = localStorage.getItem("role")
    const [userData, setUserData] = useState();
    const [showNotification, setShowNotification] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [hubConnection, setHubConnection] = useState(null)
    const [signalRNotification, setSignalRNotification] = useState([])
    const [signalRQuery, setSignalRQuery] = useState([])
    const [notificationCount, setNotificationCount] = useState()
    const [queryCount, setQueryCount] = useState()

    const navigate = useNavigate();

    async function logout() {
        RemoveToken();
        RemoveRefreshToken();
        RemoveUser();
        RemovRoles();
        localStorage.removeItem('Role');
        navigate("/Home");
        localStorage.removeItem('sesstionID');
    }

    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser?.userId;




    const isRole = localStorage.getItem('role');

    useEffect(() => {
        if (isRole == "Manager") {
            const UserGet = async () => {
                await ManagerGetById(userId).then((res) => {

                    setUserData(res?.data);

                });
            };
            UserGet();
        }
        else if (isRole == "Player") {
            const UserGet = async () => {
                await PlayerGetById(userId).then((res) => {
                    setUserData(res?.data);

                });
            };
            UserGet();
        } else if (isRole == "Web Admin") {
            const UserGet = async () => {
                await ManagerGetById(userId).then((res) => {
                    setUserData(res?.data);

                });
            };
            UserGet();
        }

    }, [])

    const fullName = userData?.firstName + ' ' + userData?.lastName;
   console.log(fullName,"fulName")

   useEffect(() => {
    // Check if fullName is undefined or an empty string
    if (!fullName || fullName.trim() === '') {
        navigate('/login'); // Navigate to login page
    }
}, [fullName, navigate]);

    useEffect(() => {
        const createHubConnection = async () => {
            const Token = localStorage.getItem('isToken')
            const hubConnect = new signalR.HubConnectionBuilder()
                .withUrl("https://staging.xcutta.com/backend/hubs", {
                    // https://gadienterprises-gamc-qa.chetu.com/hubs
                    // https://staging.xcutta.com/backend/hubs
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => Token,
                })
                .withAutomaticReconnect()
                .build();
            try {
                await hubConnect.start();

            } catch (err) {

            }
            setHubConnection(hubConnect);
        };
        createHubConnection();
    }, []);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on("ReceiveNotification", (data) => {

                setSignalRNotification(data);

                setNotificationCount(data?.length)
            });

            hubConnection.on("ContactUs", (queryData) => {
                console.log(queryData[0], "queryData");
                setSignalRQuery(queryData[0]);
                setQueryCount(queryData?.length)
            });

        }
    }, [hubConnection]);




    const handleLandingPage = () => {
        navigate("/");
    }

    const handleQueryPage = () => {
        console.log("click");
        navigate("/WebAdmin/Query");
    }

    const handleNotificationClick = () => {
        setShowNotification(!showNotification)
    };

    const handleQueryClick = () => {
        setShowQuery(!showQuery)
    };

    return (
        <>
            <div className="topbar">
                <Container fluid>

                    <Row className='align-items-center '>
                        <Col xs={6} className=' mob'> <div className='logo-image'>

                            <img src="/image/logo.svg" width={115}></img>

                        </div></Col>
                        <Col xs={6} className='text-right mob'><img src="../image/minu-icon.svg" onClick={sidebarfun} className='icon-menue'></img></Col>

                        <Col md="6" className='desk'>
                            <div className='innetr-group p-2'>

                                <div className='group-top-bar ms-0'>
                                    <div className='logo-image'>
                                        <img src="/image/logo.svg" width={115}></img>
                                    </div>
                                    <div className='icon-bar'>
                                        <img src="/image/minu-icon.svg" onClick={sidebarfun} className='icon-menue'></img>
                                    </div>

                                </div>
                            </div>
                        </Col>

                        <Col md="6">
                            <div className='d-flex p-2 align-items-center justify-content-end dropdown-section'>

                                <Button variant='outline-primary' className='me-4' onClick={handleLandingPage}>Go To Landing page</Button>
                                {
                                    isRole == "Web Admin" ?
                                        <Dropdown className='notification-message'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                <icons.Bell className='bell-notify' onClick={handleQueryClick} />
                                                {
                                                    signalRQuery?.length == 0 ? null : <span class="badge-notification" onClick={handleQueryClick}>

                                                        !
                                                    </span>
                                                }

                                            </Dropdown.Toggle>
                                            {
                                                signalRQuery?.length == 0 ? <Dropdown.Menu>
                                                    No Data Found
                                                </Dropdown.Menu> :
                                                    <Dropdown.Menu>
                                                        {
                                                            showQuery ? (
                                                                <div onClick={handleQueryPage}>

                                                                    {signalRQuery?.message}

                                                                </div>
                                                            ) : null
                                                        }
                                                    </Dropdown.Menu>
                                            }

                                        </Dropdown>

                                        :

                                        <Dropdown className='notification-message'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                <icons.Bell className='bell-notify' onClick={handleNotificationClick} />
                                                {
                                                    signalRNotification?.length == 0 ? null : <span class="badge-notification" onClick={handleNotificationClick}>

                                                        !
                                                    </span>
                                                }

                                            </Dropdown.Toggle>
                                            {
                                                signalRNotification?.length == 0 ? <Dropdown.Menu>
                                                    No Data Found
                                                </Dropdown.Menu> :
                                                    <Dropdown.Menu>
                                                        {
                                                            showNotification ? (
                                                                <div >

                                                                    {signalRNotification?.message}

                                                                </div>
                                                            ) : null
                                                        }
                                                    </Dropdown.Menu>
                                            }

                                        </Dropdown>
                                }

                                {userData?.imageName == null ? <img src="/image/profileDefault.png" width="50" className='rounded-circle me-3'></img> : <img src={ImageUrl + userData?.imageName} className='profile-image'></img>}

                                <Dropdown>
                                    {roles}
                                    <Dropdown.Toggle variant="none" className='d-flex align-items-center p-0'>

                                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip1">
                                         
                                            {userData?.firstName + " " + userData?.lastName}
                                        </Tooltip>}>
                                            <div className='text-hide'>{userData?.firstName + " " + userData?.lastName}</div>
                                        </OverlayTrigger>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        {roles == "Web Admin" ? <Dropdown>
                                            <Link to="/WebAdmin/profile" className='item-link'>
                                                Profile</Link>
                                        </Dropdown> : roles == "Super Admin" ? <Dropdown>
                                            <Link className='item-link' to="/SuperAdmin/Profile">
                                                Profile</Link>
                                        </Dropdown> : <Dropdown>
                                            <Link className='item-link' to="/Player/Profile">
                                                Profile</Link>
                                        </Dropdown>}

                                        {
                                            roles == "Web Admin" ? <Dropdown >
                                                <Link className='item-link' to="/WebAdmin/ChangePassword">
                                                    Change Password
                                                </Link></Dropdown>
                                                : roles == "Super Admin" ? <Dropdown >
                                                    <Link className='item-link' to="/SuperAdmin/ChangePassword">
                                                        Change Password
                                                    </Link></Dropdown> : <Dropdown >
                                                    <Link className='item-link' to="/Player/ChangePassword">
                                                        Change Password
                                                    </Link></Dropdown>
                                        }

                                        <Dropdown.Item onClick={logout} >
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </div>

        </>
    )
}
export default TopNavbar;


