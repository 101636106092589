import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';


const PrivateRoute = () => {
    const isAuth = localStorage.getItem('role');
     
  return (
  <>
     {isAuth ? <Outlet /> : <Navigate to="/Login" />}

  </>
     

        
  )
}

export default PrivateRoute;
