import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DoughnutChart({ monthly, today, yearly }) {
    console.log(monthly, today, yearly,"lala");
    const options = {
        animationEnabled: true,
        useHTML: true,
        subtitles: [{

            verticalAlign: "center",
            fontSize: 24,

        }],
        height: 260,
        width: 260,
        data: [{
            type: "doughnut",
            innerRadius: "50%",
            yValueFormatString: "#,###'%'",
            dataPoints: [
                { y: today, color: "#F32F45" },
                { y: yearly, color: "#F9C114" },
                { y: monthly, color: "#043BC7" },
            ]

        }],

    }

    return (

        <div >
            <CanvasJSChart options={options} />

        </div>
    )
}

export default DoughnutChart

