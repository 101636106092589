import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const WizardFormStepper = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});

  const validateStep1 = () => {
    let errors = {};
    if (formData?.firstName.trim() === '') {
      errors.firstName = 'First name is required';
    }
    if (formData?.lastName.trim() === '') {
      errors.lastName = 'Last name is required';
    }
    return errors;
  };

  const validateStep2 = () => {
    let errors = {};
    if (formData?.email.trim() === '') {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData?.email)) {
      errors.email = 'Email address is invalid';
    }
    if (formData?.password.trim() === '') {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const nextStep = () => {
    if (step === 1) {
      const errors = validateStep1();
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
      } else {
        setErrors(errors);
      }
    } else if (step === 2) {
      const errors = validateStep2();
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
      } else {
        setErrors(errors);
      }
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {


  };

  return (
    <div>

      {step === 1 && (
        <Form>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formData?.firstName}
              onChange={handleChange}
              isInvalid={!!errors?.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formData?.lastName}
              onChange={handleChange}
              isInvalid={!!errors?.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Button onClick={nextStep}>Next</Button>
        </Form>
      )}
      {step === 2 && (
        <Form>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              isInvalid={!!errors?.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData?.password}
              onChange={handleChange}
              isInvalid={!!errors?.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Button onClick={prevStep}>Previous</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </Form>
      )}
    </div>
  );
};

export default WizardFormStepper;