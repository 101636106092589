import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import * as icons from 'react-bootstrap-icons';
import { UserAll } from '../../redux/auth-reducer/auth-service';
import { Footer } from "../../Footer";
import dayjs from 'dayjs';
export const User = () => {
    const [data, setData] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [count, setCount] = useState(0);
    function sidebarfun() {
        setSidebar(!sidebar)
    }
    useEffect(() => {
        dataApi()
    }, [count]);
    const dataApi = async (event) => {
        await UserAll().then((res) => {
            setApiData(res?.data?.data.dataList)
        })
    }

    const columns = [
        {
            name: 'id',
            selector: (row, index) => index + 1,

            width: "80px"
        },
        {
            name: 'Name',
            selector: (row) => row?.firstName + " " + row?.lastName,
            sortable: true,

        },


        {
            name: 'Email',
            selector: (row) => row?.emailId,
            sortable: true,
        },

        {
            name: 'Join  Date',
            selector: (row) => dayjs(row?.createdDate).format("MM/DD/YYYY"),
            sortable: true,
        },




    ];
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };


    const filteredData = searchText ? apiData.filter((item) => {
        return item?.lastName?.toLowerCase().includes(searchText?.toLowerCase()) || item?.firstName?.toLowerCase().includes(searchText?.toLowerCase())

    })
        : apiData;
    return (

        <>

            <TopNavbar sidebarfun={sidebarfun} />

            <div className="dasboard-group" >
                <Sidebar data={sidebar} />
                <div className={sidebar ? "dashboard-content full-contnet" : "dashboard-content"}>


                    <Row>
                        <Col md="10"><h1 className="heading-dash">Players List</h1></Col>
                        <Col md="2 text-right mb-3">
                        </Col>
                    </Row>

                    <div className='table-responsive'>
                        <div className='group_data_table list-player'>
                            <div className='search-input d-flex align-items-center'>

                                <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />
                            </div>
                            <DataTable
                                title="Player List"
                                columns={columns}
                                data={filteredData}
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationTotalRows={filteredData?.length}
                            />
                        </div>
                    </div>
                </div>
                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}
