import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts';



var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export const ChartAdd2 = () => {



    const options = {
        animationEnabled: true,
        useHTML: true,
        subtitles: [{

            verticalAlign: "center",
            fontSize: 24,

        }],
        height: 260,
        width: 260,
        data: [{
            type: "doughnut",
            innerRadius: "80%",
            yValueFormatString: "#,###'%'",
            dataPoints: [
                { y: 50, color: "#F9C114" },
                { y: 50, color: "#043BC7" },
            ]

        }],

    }

    return (

        <div >
            <CanvasJSChart options={options} />

        </div>
    )
}

