import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
import { useState, useEffect } from 'react';
import { AuctionAll } from '../../redux/auth-reducer/auth-service';



var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const ChartAdd3 = ({ live, closed, upcoming }) => {

    const [auctionItemList, setAuctionItemList] = useState([])
    useEffect(() => {

        const AuctionAllFn = async () => {
            await AuctionAll().then((response) => {

                setAuctionItemList(response?.data?.data?.dataList);

            })
        }
        AuctionAllFn();

    }, []);

    const options = {
        animationEnabled: true,
        useHTML: true,
        subtitles: [{

            verticalAlign: "center",
            fontSize: 24,

        }],
        height: 260,
        width: 260,
        data: [{
            type: "doughnut",
            innerRadius: "80%",
            yValueFormatString: "",
            dataPoints: [
                { y: closed, color: "#F32F45" },
                { y: upcoming, color: "#F9C114" },
                { y: live, color: "#043BC7" },
            ]

        }],

    }




    return (

        <div >
            <CanvasJSChart options={options} />

        </div>
    )
}
