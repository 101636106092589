import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import DataTable from 'react-data-table-component';
import { PaymentHistory } from "../../redux/auth-reducer/auth-service";
import { Footer } from "../../Footer";
import dayjs from 'dayjs';


export const AccountHistory = () => {
    const [data, setData] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const sidebarfun = () => {
        setData(!data)
    }

    useEffect(() => {
        dataApi()
    }, []);
    useEffect(() => {
        dataApi();
    }, []);

    const dataApi = async () => {
        try {
            const res = await PaymentHistory();
            setApiData(res?.data?.data?.dataList);
        } catch (error) {
            console.error(error);
        }
    };

    const columns = [
        { name: 'Id', selector: (row, index) => index + 1, width: "80px" },

        { name: 'Name', selector: (row) => row.name, sortable: true },

        { name: 'Email', selector: (row) => row.email, sortable: true },

        { name: 'Amount', selector: (row) => row.amount, sortable: true },

        { name: 'Plan Type', selector: (row) => row.planType, sortable: true },

        { name: 'Status', selector: (row) => row.paymentStatus, sortable: true },

        { name: 'Payment Date', selector: (row) => dayjs(row.updatedAt).format("MM/DD/YYYY"), sortable: true },

        { name: 'Payment Time', selector: (row) => dayjs(row.updatedAt).format("HH:mm:ss"), sortable: true },
    ];

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = searchText ? apiData?.filter((item) => {
        return item?.name?.toLowerCase().includes(searchText?.toLowerCase())

    }) : apiData;

    return (
        <>
            <TopNavbar sidebarfun={sidebarfun} />
            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Account History</h1>
                    <div className='group_data_table'>
                        <div className="table-transaction">
                            <div className='table-responsive'>
                                <div className='search-input d-flex align-items-center'>
                                    <input
                                        type="text"
                                        className='form-control mb-3 mt-3'
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={handleSearch}
                                    />
                                </div>

                                <DataTable
                                    title="Player List"
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 20, 30]}
                                    paginationTotalRows={filteredData?.length}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className={data ? "Footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>



        </>
    )
}
