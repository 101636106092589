import React, { useEffect, useState } from 'react'
import generator from "tournament-generator";
import _ from "lodash";
import { Bracket, Seed, SeedItem, SeedTeam } from "react-brackets";
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { Footer } from '../../Footer';
import { useLocation, useParams } from 'react-router-dom';
import { BracketUpdate, TeamAll, TeamGetAll, TournamentBarcketId, TournamentUpdateTeamDate, TournamentUpdateTeamScore } from '../../redux/auth-reducer/auth-service';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move'
import { setSeconds } from 'rsuite/esm/utils/dateUtils';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from 'react-bootstrap/Modal';
import * as icons from 'react-bootstrap-icons';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { DatePicker } from 'rsuite';
import { ToastContainer, toast } from 'react-toastify';
import dayjs from 'dayjs';
export const SetupBracket = (props) => {

  const [data, setData] = useState(false);
  const [roundData, setRoundData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editModeByIndex, setEditModeByIndex] = useState("");
  const [secondsId, setSecondsId] = useState();
  const [scondMtachId, setScondMtachId] = useState();
  const [matchIdScore, setMatchIdScore] = useState([]);
  const [tournamentIdScore, setTournamentIdScore] = useState()
  const [firstMatchIdItem, setFirstMatchIdItem] = useState();
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [dataList, setDataList] = useState({
    leftTeamScore: 0,
    rightTeamScore: 0,
  });
  const [scoreData, setScoreData] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const handleClose = () => setShow(false);
  const [startMatch, setStartMatch] = useState()
  const handleShow = (list) => {

    setDataList(list)
    // setCheckList(true);
    setShow(true);

  }
  console.log(dataList, "dataList")
  const [dateUpdate, setDateUpdate] = useState();
  const [scoreUpdate, setScoreUpdate] = useState({
    matchId: 0,
    tournamentId: 0,
    leftTeamScore: 0,
    rightTeamScore: 0,
    isCompleted: true
  })
  const { id } = useParams();

  function sidebarfun() {
    setData(!data)
  }

  useEffect(() => {
    Bracketfn()
  }, []);

  useEffect(() => {
    if (dataList?.matchStartDate) {
      const startDate = new Date(dataList?.matchStartDate);
      const timezoneOffset = startDate.getTimezoneOffset();
      startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
      setStartMatch(startDate);
    }
  }, [dataList]);

  console.log(startMatch, "startMatch");
  console.log(dataList, "dataList");

  const Bracketfn = async () => {
    await TournamentBarcketId(id).then((res) => {
      setRoundData(res)
      // if(res?.status == true){
      //  setRoundData(res)
      //  toast.success(res?.message);
      // }
      // else{
      //   toast.error(res?.message);
      // }

    })
  }

  useEffect(() => {
    const BracketfnGet = async () => {
      await TournamentBarcketId(id).then((res) => {
        console.log(res, "res>>>>>>>>>>>")
        setScoreData(res)
      })
    }
    BracketfnGet()
  }, [count]);

  useEffect(() => {
    roundData?.map((item) => {
      // {item?.tournamentId};
      setTournamentIdScore(item?.tournamentId);
      console.log(item?.matchId, "dsfdf")
      setMatchIdScore(item?.matchId)

    })
  })

  const handleEditClickTeam = (ind, id) => {
    console.log(ind, id, "??????")
    setFirstMatchIdItem(id)
    setEditModeByIndex(ind);
    setEditMode(true);
  }
  const handelOnChange = (e) => {
    const [value, array] = e.target.value.split(",");
    setSecondsId(array);
    setScondMtachId(value);
    console.log(value, "value");
    console.log(array, "array");
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handelDate = (e) => {
    console.log(e, "dfdf");
    // setDateUpdate(e)
    setStartMatch(e)

  }

  const handleDateUpdate = async () => {
    const data =
    {
      "matchId": dataList?.matchId,
      "tournamentId": tournamentIdScore,
      "matchStartDate": startMatch
    }
    await TournamentUpdateTeamDate(data).then((res) => {
      if (res?.status == true) {
        console.log(res?.message, "res");
        toast.success(res?.message);
        setCount(count + 1);
        setShow(false);
      }
      else {
        toast.error(res?.message);
      }
    })
  }

  const handleScorecheck = async () => {
    const data = {
      "matchId": dataList?.matchId,
      "tournamentId": tournamentIdScore,
      "leftTeamScore": Number(dataList?.leftTeamScore),
      "rightTeamScore": Number(dataList?.rightTeamScore),
      "isCompleted": isChecked
    };
    console.log(data, "data");

    await TournamentUpdateTeamScore(data).then(async (res) => {
      if (res?.status === true) {
        console.log(res?.message, "res");
        toast.success(res?.message);
        setCount(count + 1);
        setShow(false);
        // Fetch updated score data after successful update
      } else {
        toast.error(res?.message);
      }
    });
  };
  const closebtn = () => {
    setEditMode(false);
  }
  const handelScoreUpdate = (e) => {
    setDataList({
      ...dataList,
      [e.target.name]: e.target.value
    })
  }
  var roundDataTitle = []
  var roundDataLeftTeam = []
  roundData?.map((item) => {
    roundDataTitle.push(item?.matchLevel);
  })
  roundDataTitle = [...new Set(roundDataTitle)].sort((b, a) => a - b);
  let round = roundDataTitle?.map((value, index) => {
    let dataItem = {
      title:
        <>

        </>,
      seeds: [],
    }
    let count = 0;
    const convertUTCToLocalTime = (utcDateString) => {
      const date = new Date(utcDateString); // Create a Date object from the UTC string
      return date.toLocaleString(); // Convert to local time string
  };


    roundData?.map((item, ind) => {
      const handleSaveClick = async () => {
      
        const Updatedata =
        {
          "tournamentId": Number(id),
          "firstTeamMatchId": firstMatchIdItem,
          "firstTeamId": editModeByIndex,
          "secondTeamMatchId": Number(scondMtachId),
          "secondTeamId": Number(secondsId),
        }
        await BracketUpdate(Updatedata).then((res) => {
          if (res?.status == true) {
            Bracketfn();
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
        })
        setEditMode(false);
      };

      if (value == item.matchLevel) {
        dataItem.seeds.push({
          
          id: item.matchLevel,
          date: `[${new Date().toISOString()}]`,
          teams: item.rightTeamId > 0 ? [{
            name: <>
              <button className='saveScoreBtn' onClick={() => handleShow(item)}>Score</button>
              <span className='back-count'>
                <p>{scoreData?.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore !== null ? scoreData.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore : 0}</p>
              </span>
              {editMode && editModeByIndex == item.leftTeamId ? (
                <select onChange={handelOnChange} >
                  <option>Select</option>
                  {roundData?.map((itemList, i) => {
                    return (
                      <>
                        <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
                        <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
                      </>
                    )
                  })}
                </select>
              ) : (
                <>
                  <span>{item?.leftTeamName}</span>
                </>
              )
              }

              {editMode && editModeByIndex == item.leftTeamId ? (
                <>
                  <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /></button>
                  <button ><icons.XLg onClick={closebtn} /></button>
                </>
              ) : (
                <button onClick={() => handleEditClickTeam(item?.leftTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /></button>
              )}

            </>
          },
          {
            name: <>

              <span className='back-count'>
                <p>{scoreData?.find(data => data.rightTeamId === item.rightTeamId)?.rightTeamScore !== null ? scoreData.find(data => data.rightTeamId === item.rightTeamId)?.rightTeamScore : 0}</p>
              </span>

              {editMode && editModeByIndex == item.rightTeamId ? (
                <select onChange={handelOnChange}>
                  <option>Select</option>
                  {roundData?.map((itemList, i) => {
                    return (
                      <>
                        <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
                        <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
                      </>
                    )
                  })}
                </select>
              ) : (
                <>
                  <span>{item?.rightTeamName}</span><br></br>
                 
                  {item?.matchStartDate === "0001-01-01T00:00:00" ? null : (() => {
                        const localTime = convertUTCToLocalTime(item?.matchStartDate + "Z");
                        const [datePart, timePart] = localTime.split(", ");
                        
                        return (
                            <p className='date-match'>
                               {dayjs(item?.matchStartDate).format("MM/DD/YYYY")}  {timePart}
                            </p>
                        );
                    })()}
                  

                </>
              )

              }

              {

                editMode && editModeByIndex == item.rightTeamId ? (<>
                  <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /></button>
                  <button ><icons.XLg onClick={closebtn} /></button></>
                ) : (
                  <button onClick={() => handleEditClickTeam(item.rightTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /> </button>
                )

              }

            </>
          }

          ]

            : [{
              name: <>
                <span className='me-3'>

                  {editMode ? (
                    <select onChange={handelOnChange}>
                      <option>Select</option>
                      {roundData?.map((itemList, i) => {
                        return (
                          <>
                            <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
                            <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
                          </>
                        )
                      })}
                    </select>
                  ) : (
                    <span>{item?.leftTeamName}</span>
                  )}
                  {editMode ? (
                    <>
                      <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /></button>
                      <button ><icons.XLg onClick={closebtn} /></button></>
                  ) : (
                    <button onClick={() => handleEditClickTeam(item.rightTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /></button>
                  )}
                </span> </>
            }],
        });
        if (item.rightTeamId > 0) {
          count += 2;
        } else {
          count += 1;
        }
      }
    });
    return dataItem
  });

  const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }) => {
    return (
      <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem>
          <div>
            <SeedTeam>{seed.teams[0]?.name || 'NO TEAM '}</SeedTeam>
            {seed.teams[1]?.name && <SeedTeam>{seed.teams[1]?.name || 'NO TEAM '}</SeedTeam>}
          </div>
        </SeedItem>
      </Seed>
    );
  };

  return (
    <>
      <ToastContainer />
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <div class="bracket  disable-image bg-white ">
            <Bracket rounds={round} renderSeedComponent={CustomSeed} />
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Update Scores / Date</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Tabs
                  defaultActiveKey="Score"
                  id="auctiontabs"
                  className="mb-3 tab-list-item"
                  justify
                >
                  <Tab eventKey="Score" title="Score">
                    <Form.Group as={Row} className="" >
                      <Form.Label column sm="2" className='p-0'>

                        <Form.Control
                          required
                          type="number"
                          name="leftTeamScore"
                          value={dataList?.leftTeamScore == null ? 0 : dataList?.leftTeamScore}
                          maxLength={50}
                          onChange={handelScoreUpdate}

                        />
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control type="text" value={dataList?.leftTeamName} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="" >
                      <Form.Label column sm="2" className='p-0'>
                        <Form.Control
                          required
                          type="number"
                          name="rightTeamScore"
                          value={dataList?.rightTeamScore == null ? 0 : dataList?.rightTeamScore}
                          maxLength={50}
                          onChange={handelScoreUpdate}

                        />
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control type="text" value={dataList?.rightTeamName} />
                      </Col>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" checked={isChecked}
                        onChange={handleCheckboxChange} label="Match Completed" />
                    </Form.Group>

                    {/* <p>By Proceeding, you will not be able to update scores again.</p> */}

                    <Button variant="outline-secondary" className='me-3' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="success" onClick={handleScorecheck}>
                      Submit
                    </Button>
                  </Tab>
                  <Tab eventKey="profile" title="Date">
                    <Form.Group as={Row} className="mb-3" >

                      <DatePicker placeholder="Select Date" format="MM/dd/yyyy hh:mm aa" value={startMatch} disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)} onChange={handelDate} name="matchStartDate" className='Auction-Date' />

                    </Form.Group>
                    <Button variant="success" onClick={handleDateUpdate}>
                      Submit
                    </Button>
                  </Tab>

                </Tabs>
       
              </Modal.Body>
              <Modal.Footer>

              </Modal.Footer>
            </Modal>

          </div>

        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>

  );
}


