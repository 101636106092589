import React, { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as icons from "react-bootstrap-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DataTable from "react-data-table-component";
import { ModalBox } from "../Outer/Modal";
import { AdminReg, AdminGet } from "../../redux/auth-reducer/auth-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Admin = () => {
  const [searchText, setSearchText] = useState("");
  const [showView, setShowView] = useState(false);
  const [adminDeatils, setAdminDeatils] = useState();
  const [dataSide, setDataSide] = useState();
  const [data, setData] = useState({
    managerEmailId: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    password: "",
    adminPassword: "",
  });

  function sidebarfun() {
    setData(!dataSide)
  }
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,

    },
    {
      name: "First Name",
      selector: (row) => row?.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row?.lastName,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row?.emailId,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row?.phoneNumber,
      sortable: true,
    },
    {
      name: "Organization Name",
      selector: (row) => row?.orgName,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <>
          <button type="button" className="text-danger btn-aucion">
           <icons.Trash />
          </button>
          <button type="button" className="text-success btn-aucion ms-1">
           <icons.PencilSquare />
          </button>

        </>
      ),
      sortable: true,
    },
  ];


  const viewFn = (viewItem) => {
    setShowView(true);
  };
  const handleClosed = () => {
    setShowView(false);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = searchText
    ? adminDeatils.filter((item) => {
      return (
        item?.firstName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.lastName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.orgName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.emailId?.toLowerCase().includes(searchText?.toLowerCase())
      );
    })
    : adminDeatils;


  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    let name = e.target.name;
    setData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const handleSave = async () => {
    handleClosed();
    setData("");
    const dataRegister = [
      {
        crudOperationId: true,
        id: 0,
        managerEmailId: data?.managerEmailId,
        orgId: 0,
        firstName: data?.firstName,
        lastName: data?.lastName,
        emailId: data?.emailId,
        phoneNumber: data?.phoneNumber,
        password: data?.password,
        adminPassword: data?.adminPassword,
      },
    ];

    await AdminReg(dataRegister).then((res) => {
      if (res.status == 200) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };

  const getAdminData = async () => {
    await AdminGet().then((res) => {
      setAdminDeatils(res?.data?.data?.dataList, "res");
    });
  };

  useEffect(() => {
    getAdminData();
  }, []);

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="dasboard-group">
        <TopNavbar sidebarfun={sidebarfun} />
        <Sidebar data={dataSide} />
        <div
          className={
            dataSide ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >

          <Row className="d-flex align-items-center">
            <Col md={6}>
              <h1 className="heading-dash">Admin</h1>
            </Col>
            <Col md={6} className="text-right">
              <Button
                className="primary button-create-auction btn btn-primary "
                onClick={() => viewFn()}
              >
                <icons.Plus class="icon-plus" /> Add Admin
              </Button>
            </Col>
          </Row>
          <div className="group_data_table admin">
            <div className="search-input d-flex align-items-center">
              <label className="me-3">Search</label>
              <input
                type="text"
                className="form-control mb-3 mt-3"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
              />
            </div>
            <DataTable
              title="Admin"
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}

            />
          </div>
        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>

      <ModalBox
        show={showView}
        title={
          <>
            <h1 className="heading-dash">Add Admin</h1>
          </>
        }
        body={
          <>
            <Form>
              <Row>
                <Col>

                  <Form className="mb-2">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={data?.firstName}
                      onChange={(e) => handleChange(e)}
                    />

                  </Form>
                </Col>
                <Col>
                  <Form className="mb-2">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="Text"
                      name="lastName"
                      value={data?.lastName}
                      onChange={(e) => handleChange(e)}
                    />

                  </Form>
                </Col>
              </Row>

              <Row>
                <Col>

                  <Form className="mb-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="emailId"
                      value={data?.emailId}
                      onChange={(e) => handleChange(e)}
                    />

                  </Form>
                </Col>
                <Col>
                  <Form className="mb-2">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="phoneNumber"
                      value={data?.phoneNumber}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form>
                </Col>
              </Row>

              <Row>

                <Col>
                  <Form className="mb-2">
                    <Form.Label>Xcutta Password</Form.Label>
                    <Form.Control
                      type="email"
                      name="password"
                      value={data?.password}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form>
                </Col>
                <Col>
                  <Form className="mb-2">
                    <Form.Label>Temporary Password for Admin </Form.Label>
                    <Form.Control
                      type="password"
                      name="adminPassword"
                      value={data?.adminPassword}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form>
                </Col>
              </Row>
            </Form>
          </>
        }
        footer={
          <>
            <Button
              type="button"
              className="btn-Cancel secondary mb-3"
              onClick={handleClosed}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn-Save me-3 mb-3"
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        }
      />
    </>
  );
};
