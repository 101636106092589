import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import { Button, Card, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import * as icons from 'react-bootstrap-icons';
import { DatePicker, Loader } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import { useNavigate } from 'react-router-dom';
import { AuctionRegApi, SportAllMap, TeamGetAll, TournamentAll, UploadImage, UserGetId, WinCount } from '../../redux/auth-reducer/auth-service';
import { toast, ToastContainer } from 'react-toastify';
import { Footer } from '../../Footer';
import { ImageUrl } from '../../utils/config/config';

export const CreateAuction = () => {
  const [data, setData] = useState();
  const [validated, setValidated] = useState(false);
  const [imageName, setImageName] = useState('');
  const [itemList, setItemList] = useState();
  const [seletedTournament, setSeletedTournament] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [itemSport, setItemSport] = useState([]);
  const [dataImage, setDataImage] = useState();
  const [itemTeam, setItemTeam] = useState();
  const [secondryMarketStatus, setSecondryMarketStatus] = useState("");
  const [teamFkID, setTeamFkID] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [allXcuttaPercentage, setAllXcuttaPercentage] = useState();
  const [errorMessageXcutta, setErrorMessageXcutta] = useState(false);
  const [userData, setUserData] = useState();
  const [idtournament, setIdtournament] = useState();
  const [winingPlace, setWiningPlace] = useState();
  const [marketStatus,setMarketStatus] =useState(false)
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [teamReg, setTeamReg] = useState({
    auctionId: 0,
    auctionName: "",
    auctionImage: "",
    tournamentId: 0,
    sportId: 0,
    teamId: 0,
    startDate: "",
    bidTime: 0,
    startingBid: 0,
    bidIncrement: 0,
    xcuttaTake: 0,
    winnerTitle: [],
    winnerPercentage: [],
    createdBy: userData?.id,
    secondaryMarket: true
  })
  const [winningPlaceInptData, setWinningPlaceInptData] = useState([]);
  function sidebarfun() {
    setData(!data)
  }
  useEffect(() => {
    if (isDisabled) {
      setIsDisabled(true);

    }
  }, [isDisabled]);

  useEffect(() => {
    if (isButtonDisabled) {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);
    }
  }, [isButtonDisabled]);

  var SportIdSelect = []
  itemSport?.map((value) => {
    if (value?.sportsId == seletedTournament) {
      return SportIdSelect.push(value?.sportsId);
    }
  })

  const blockInvalidChar = (e) =>
    [
      "+",
      "-",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ].includes(e.key) && e.preventDefault();

  useEffect(() => {
    async function UserGet() {
      await UserGetId().then((res) => {
        setUserData(res);
      })
    }
    UserGet();
  }, []);




  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (teamReg.startDate < new Date()) {
      toast.error('Your selected start date and time has already expired. Please select a different time.');
    } else {

      setIsButtonDisabled(true);
      setValidated(true);

      let totalPer = winningPlaceInptData.reduce((acc, item) => {
        item.input.map((elem) => {
          acc += Number(elem.winnerPercentage)
        })
        return acc
      }, 0)

      let winnerPercentage = winningPlaceInptData.reduce((acc, item) => {
        item.input.map((elem) => {
          acc.push(Number(elem.winnerPercentage))
        })
        return acc
      }, [])

      let winnertitle = winningPlaceInptData.reduce((acc, item) => {
        item.input.map((elem) => {
          acc.push((elem.name))
        })
        return acc;
      }, [])

      totalPer += Number(teamReg?.xcuttaTake)

      if (teamReg?.xcuttaTake.length > 0) {
        setErrorMessageXcutta(false);
      }
      else {
        setErrorMessageXcutta(true);
      }

      if (teamReg?.winnerCount) {
    
        if (totalPer == 100) {
        
        } else {
          
          return setErrorMessage(true);
        }

      }

      const isUser = JSON.parse(localStorage.getItem('isUser'));
      const userId = isUser?.userId;

    

      const RegisterData = [
        {
          "crudOperationId": true,
          "auctionName": teamReg?.auctionName,
          "auctionImage": dataImage || "",
          "tournamentId": teamReg?.tournamentId,
          "sportId": SportIdSelect.toString(),
          "teamId": teamFkID,
          "startDate": teamReg?.startDate,
          "startingBid": teamReg?.startingBid,
          "bidIncrement": teamReg?.bidIncrement,
          "xcuttaTake": teamReg?.xcuttaTake,
          "winnerCount": teamReg?.winnerCount,
          "bidTime": teamReg?.bidTime,
          "winnerTitle": winnertitle,
          "winnerPercentage": winnerPercentage,
          "createdBy": userId,
          "secondaryMarket": marketStatus,
          "isPrivate":secondryMarketStatus || false
        }
      ];
    

      if (teamReg.auctionName == "" || teamReg.startDate == "" || teamReg.startingBid == 0 || teamReg.bidIncrement == 0 || teamReg.xcuttaTake == 0 || teamReg.bidTime == 0 || teamReg.winnerTitle == [] || teamReg.winnerPercentage == []) {
        toast.error("field is required");
      } else {
        await AuctionRegApi(RegisterData).then((res) => {
          if (res?.data?.status == true) {
            toast.success(res?.data?.message);
            setLoading(false);
            setTimeout(() => {
              navigate('/SuperAdmin/Auction')
            }, 2000)

          } else {
            toast.error(res?.data?.message);
          }

        })
      }

    }

  }

  useEffect(() => {

    const TournamentAllFn = async () => {
      await TournamentAll().then((response) => {

        setItemList(response?.data?.data?.dataList);

      })
    }
    TournamentAllFn();

  }, [])


  useEffect(() => {

    const SportAllFn = async () => {
      await SportAllMap().then((response) => {
        setItemSport(response?.data?.data?.dataList)
      })
    }
    SportAllFn();

  }, [])

  const handleFileChange = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
   
    setImageName(event.target.files[0].name)

    const dataList = (event.target.files[0]);

    const imageAPI = async () => {
      const formDataImage = new FormData();
      formDataImage.append('file', dataList);
    
      await UploadImage(formDataImage).then((res) => {
        if (res?.data?.status) {
      
          setDataImage(res?.data?.message);

        } else {
          toast.error(res?.message);
        }
      })
    }

    imageAPI()
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    const formatted = date.toLocaleString('en-US', options);


    const [month, day, year, time] = formatted.split(/[/,\s]+/);
    return `${month}/${day}/${year} ${time}`;
  };
  const handelDate = async (event) => {

    const utcDate = new Date(event);
    const istDate = new Date(utcDate.getTime());

    const formattedDate = formatDate(istDate);
  
    setTeamReg({
      ...teamReg,
      startDate: event,
    },)

  };

  const handelVisible = (e) => {
    setMarketStatus(true);
     setIsShow(!isShow)
 
 
   }
  const navigate = useNavigate();
  const cancelbtn = (e) => {
    navigate(-1)
  }

  const handleSelectedTurnament = (event) => {

    const [value, array] = event.target.value.split("-");
    const [value1, textContent] = array.split("=")
    setIsDisabled(false)
    setTeamFkID(array.split(",").map((e) => parseInt(e)))
    setSeletedTournament(value);
    setTeamReg({
      ...teamReg,
      tournamentId: parseInt(textContent),
    },)
    let teamsLength = array.split(",").map((e) => parseInt(e)).length
    setIdtournament(textContent)
    if (teamsLength) {
      const AuctionWiningPlace = async () => {
        await WinCount(teamsLength).then((response) => {

          setWiningPlace(response?.data)
        })
      }
      AuctionWiningPlace();
    }
  }



  useEffect(() => {
    const TeamAllFn = async () => {
      await TeamGetAll().then((response) => {
        setItemTeam(response?.data?.data?.dataList);
      })
    }
    TeamAllFn();

  }, [])

  const handelAuction = (e) => {

    setTeamReg({
      ...teamReg,
      [e.target.name]: e.target.value,
    },)
    setErrorMessage(false)
    setErrorMessageXcutta(false);
  }

  const handelAuctionTake = (e) => {
    setAllXcuttaPercentage(100 - e.target.value)
    const value = e.target.value;
    if (value >= 0) {
      setInputValue(value);
    }
    setTeamReg({
      ...teamReg,
      [e.target.name]: e.target.value,
    },)
    setErrorMessage(false)
    setErrorMessageXcutta(false);
  }

  const handelAuctionWinner = (e) => {
    const { name, value } = e.target;
    let newTeamReg = { ...teamReg };
    if (name === "winnerPercentage") {
      const index = parseInt(e.target.dataset.index, 10);
      newTeamReg.winnerPercentage[index] = Number(value);
    } else {
      newTeamReg[name] = value;
    }

    setTeamReg(newTeamReg);
    setErrorMessage(false);
  }




  const handelCount = (e) => {
    setTeamReg({
      ...teamReg,
      [e.target.name]: winingPlace[e.target.value].level,
    },);

  

    const data = []
    let currentWinnerPlace = 1;

    winingPlace.map((item, index) => {
      if (index <= e.target.value) {

        data.push({ name: item.level, input: Array(item.winnerCounts).fill(1).map((inp, ind) => ({ name: `Place ${currentWinnerPlace++}`, value: `` })) })
      }

    })


    setWinningPlaceInptData(data);
  }


  const handelSecondry = (e) => {
    const selectedValue = e.target.value;
    setTeamReg({
      ...teamReg,
      secondaryMarket: selectedValue,
    });


    if (selectedValue === 'Public') {
      setSecondryMarketStatus(true);
    } else if (selectedValue === 'Private') {
      setSecondryMarketStatus(false);
    }
  };

  const [fnDisabled, setFnDisabled] = useState(true);





  const handleWinningPlaceChange = (levelIndex, placeIndex) => (event) => {
    const { name, value } = event.target
    const copy = [...winningPlaceInptData]
    copy[levelIndex].input[placeIndex][name] = value
    setWinningPlaceInptData(copy);

  }

  const getOrdinalSuffix = (number) => {
    // const suffixes = ["th", "st", "nd", "rd"];
    // const value = number % 100;
    // return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  };

  return (
    <>

      <ToastContainer />

      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group " >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content "}>
          <Row>
            <Col md={6}><h1 className="heading-dash">Auction</h1>

            </Col>

            <Col md={12}>
              <Card className='p-3'>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Col md={12}><h5 className="heading-auction">Create New Auction</h5></Col>
                  <Row>
                    <Col md={4} className='text-center'>
                      <Form.Label className='mt-5'>Upload  a Logo</Form.Label>

                      <Form.Label for="browse" name="auctionImage" className='image-chosse' onChange={handleFileChange}>
                        <icons.CameraFill className='icon-camera' />
                        <input id="browse" type="file" accept=".jpg,.jpeg,.png" hidden required />

                      </Form.Label>
                      <p className='mt-2 image-upload-mess'>(jpeg, jpg, png and size upto 2 mb)<span className='text-danger'>*</span></p>
                      {loading && <Loader />}
                           
                           {dataImage ? <img src={ImageUrl + dataImage} alt="Selected" width={100} /> : ""}
                      <Form.Control.Feedback type="invalid">
                        Please select a Tournament.
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6} className='border-custom'>
                      <Form.Group className="text-left">
                        <Form.Label>Select Tournament</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Select name="tournamentId" className='select-option tournament' required as="select" onChange={handleSelectedTurnament}>
                            <option>Select Tournament</option>
                            {
                              itemList?.map((item) =>
                                <option key={item?.tournamentId} value={`${item?.sportsId} - ${item.teamId} = ${item?.tournamentId}`}>{item?.tournamentName}</option>)}

                          </Form.Select >
                          <Form.Control.Feedback type="invalid">
                            Please select a Tournament.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <p className={`${seletedTournament ? "mt-3 label" : ""}`}> {
                        `${seletedTournament ? "Sport Name" : ""}`
                      }  </p>

                      <p className={`${seletedTournament ? "input-game" : ""}`}>{itemSport?.map((value) => value?.sportsId == seletedTournament && value?.sportsName)}</p>

                      {seletedTournament ?
                        <Table className='table-team border mt-3'>
                          <thead>
                            <tr>
                              <th>Team</th>
                              <th>Name</th>

                            </tr>
                          </thead>
                          {

                            itemTeam?.map((listItem) => teamFkID?.map((item) =>
                              item == listItem?.teamId &&

                              <tbody>
                                <tr>
                                  <td><img src="../image/image-team.png"></img></td>
                                  <td >{listItem?.teamName}</td>
                                </tr>

                              </tbody>

                            ))

                          }
                        </Table> : ""}

                      <Row>
                        <Form.Group as={Col} md={6} controlId="validationCustom02">
                          <Form.Label>Auction Name</Form.Label>
                          <Form.Control name="auctionName" maxLength={50} onChange={handelAuction} className='select-option tournament' required type="text" placeholder="Enter your Auction Name" />
                          <Form.Control.Feedback type="invalid">
                            Please enter Auction Name.
                          </Form.Control.Feedback>
                        </Form.Group>



                        <Form.Group as={Col} md={6}>
                          <Form.Label>Auction Start Date</Form.Label>
                          <DatePicker format="MM/dd/yyyy hh:mm aa" placeholder="Start Date"
                            disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)}
                            name="startDate" className=' Auction-Date'
                            onChange={handelDate} required />

                          <Form.Control.Feedback type="invalid">
                            Please enter Auction Start Date.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <Form.Label className='mt-3'>Bid Time Limit</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Select name="bidTime" onChange={handelAuction} className='select-option tournament' required as="select">
                              <option value="" >Select Time Limit</option>
                              <option value={0.5} >0.5 minutes</option>
                              <option value={1} >1 minutes</option>
                              <option value={2} >2 minutes</option>
                              <option value={3}>3 minutes</option>
                              <option value={4} >4 minutes</option>
                              <option value={5} >5 minutes</option>
                              <option value={6} >6 minutes</option>
                              <option value={7} >7 minutes</option>
                              <option value={8} >8 minutes</option>
                              <option value={9} >9 minutes</option>
                              <option value={10} >10 minutes</option>
                            </Form.Select >
                            <Form.Control.Feedback type="invalid">
                              Please add Bid Time Limit.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>


                        <Form.Group as={Col} md={6}>
                          <Form.Label className='mt-3'>Manager Hold ( % )</Form.Label>
                          <input name="xcuttaTake" value={inputValue} onChange={handelAuctionTake} className='select-option tournament xcuttaTakeinput' type="number" placeholder="Enter your cut" onKeyDown={blockInvalidChar} />
                          {
                            errorMessageXcutta ? <p className='err-message'>Please enter Xcutta take.</p> : ""
                          }

                        </Form.Group>

                        <Form.Group as={Col} md="6 mt-3">

                          <Form.Label>Starting Bid</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="text"
                              name="startingBid"
                              onChange={handelAuction}
                              placeholder="Enter your Starting Bid"
                              aria-describedby="inputGroupPrepend"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a starting bid.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="6 mt-3">

                          <Form.Label>Minimum Bid Increment</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="text"
                              name="bidIncrement"
                              onChange={handelAuction}
                              placeholder="Enter your Bid Increment"
                              aria-describedby="inputGroupPrepend"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a  bid increment.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>







                      </Row>

                      {
                        allXcuttaPercentage === 0 || allXcuttaPercentage < 0
                          ? ''
                          : <>
                            <p className='winner-distribution'>Winner Prize Distribution {allXcuttaPercentage} %</p>
                            <Form.Label>Total Winners</Form.Label>
                            <Form.Select name="winnerCount" disabled={isDisabled} onChange={handelCount} className='select-option tournament' required as="select">
                              <option value={""} >Select Winner</option>

                              {
                                winingPlace?.map((item, index) => (
                                  <option key={index} value={index}> {index + 1} </option>
                                ))
                              }
                            </Form.Select >
                          </>
                      }




                      {
                        teamReg?.winnerCount ? <div className='winner-percentage'>
                          <Row>
                            {
                              winningPlaceInptData?.map((item, levelIndex) => {
                                return (
                                  <Col md={12}>

                                    {item?.input?.map((elem, j) => {
                                      return (
                                        <div key={`input-${j}`}>
                                          <input
                                            type='text'
                                            className='input-editable'
                                            name="name"
                                            value={elem.name}
                                            onChange={handleWinningPlaceChange(levelIndex, j)}
                                            disabled={fnDisabled}
                                          />


                                          <input
                                            name="winnerPercentage"
                                            className='select-option tournament xcuttaTakeinput mb-2'
                                            placeholder='Enter Percentage'
                                            type="number"
                                            onChange={handleWinningPlaceChange(levelIndex, j)}
                                            value={elem.winnerPercentage}
                                          />
                                        </div>
                                      )
                                    })}


                                  </Col>
                                )
                              })
                            }

                          </Row>

                          {
                            errorMessage ? <p className='err-message'>Please ensure that the total distribution among the winner should be equal {allXcuttaPercentage}%</p> : ""
                          }
                        </div> : ""
                      }

<label for="market" className='input-check-group mt-3'  >
                        <input type="checkbox" id="market" name ="market" className='inputCheck' onClick={handelVisible} />Secondary Market</label>
                      {
                        isShow ? <Form.Group as={Col} className='mt-3' controlId="validationCustom02">
                          <Form.Label>Types of Secondary Market</Form.Label>
                          <InputGroup hasValidation>
                          <Form.Select 
                            name="secondaryMarket" 
                            onChange={handelSecondry} 
                            className='select-option tournament' >
                            <option value="">Select an option</option>
                            <option value={'Public'}>Public</option>
                            <option value={'Private'}>Private</option>
                          </Form.Select>
                        
                        </InputGroup>
                        </Form.Group> : ""
                      }
                      <button type="button" className='cancel-btn mt-3' onClick={cancelbtn} >Cancel</button>
                      <Button type="submit" disabled={isButtonDisabled} className='update-btn-prof '>Save</Button>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </Form>
              </Card>
            </Col>

          </Row>

        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  )
}


