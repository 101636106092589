import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
export const PageNotFound = () => {
  const navigate = useNavigate()
  const backBtn = () => {
    navigate(-1);
  }
  return (
    <>
      <div className='pagenotfound-section'>
        <div className='text-center'>
          <h1>404</h1>
          <h3>Oops!, page not found</h3>
          <p>The page you are looking for doesn't exist or an other error occurred</p>
          <Button type='button' className='back_button' onClick={backBtn}>Go Back</Button>
        </div>
      </div>
    </>
  )
}
