import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { CustomerAddPayment, UserGetId } from '../../redux/auth-reducer/auth-service';
import { useNavigate } from 'react-router-dom';


export const CreateCustomer = () => {
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState(false);
    const [userData, setUserData] =useState();
    const navigate =useNavigate();
     const [formReg,setFormReg]=useState({
        name:"",
        email:""
    })
    function sidebarfun() {
        setData(!data)
      }
      const handleSubmit = async (event) => {
        event.preventDefault();
        setValidated(true);
        const data = {
            "name": userData?.name,
            "email": userData?.emailId,
          }
      
        await CustomerAddPayment(data).then((res) => {
            if (res?.status == 200) {
                let getData=res?.data?.data
              navigate("/Player/Checkout", { state: getData });
            } else {
              console.log("error");
            }
          });
          
    }
    const handelForm =(e)=>{
        setFormReg({...formReg,
        [e.target.name]:e.target.value}
    )
    }
    useEffect(() => {
        async function UserGet() {
          await UserGetId().then((res) => {
            setUserData(res,"fff");
      
          })
        }
        UserGet();
      }, []);
  return (
  <>
    <TopNavbar sidebarfun={sidebarfun} />
          <div className="dasboard-group" >

        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
      
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
     
     <Row className="mb-3 align-items-center justify-content-center">
         <div  className='card checkout-form'>
         <h1 className="heading-dash text-dark text-center">Create User</h1>
         <hr></hr>
    

         <Form.Group className="mb-3" controlId="validationCustom01">
             <Form.Label>Email</Form.Label>
             <Form.Control
                 required
                 type="text"
                 name="email"
                 maxLength={50}
                 onChange={handelForm}
                 value={userData?.emailId}
                 placeholder="Email"
             />
             <Form.Control.Feedback type="invalid">
                 Please enter valid Email.
             </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3" controlId="validationCustom01">
             <Form.Label>Name</Form.Label>
             <Form.Control
                 required
                 type="text"
                 name="name"
                 maxLength={50}
                 onChange={handelForm}
                 value={userData?.firstName}
                 placeholder="Name"
             />
             <Form.Control.Feedback type="invalid">
                 Please enter valid name.
             </Form.Control.Feedback>
         </Form.Group>
         <Row>
        <Col as ={Col} md={4}></Col>
        <Col as={Col} md={8} className='text-right'> 
            <Button type="submit"  className='btn-Save me-3'>Save</Button>
            <Button type="button" className='btn-Cancel secondary'  >Cancel</Button>
        </Col>
        </Row>
         </div>
     </Row>
    

 </Form>
        </div>
        
        </div>
  </>
  )
}
