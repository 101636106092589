import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import * as icons from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
export const PaymentCancel = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/Home');
    }, 5000);
  }, []);
  return (
  <>
   <section className='payment-success'>
        <Container>
          <Row className='align-items-center justify-content-center'>
            <Col md={6} className='text-center'>
              <icons.XCircle className='payment-check-cancel' />
              <h2>Payment cancelled</h2>
              <p>The payment has beeen cancelled</p>
            </Col>
            <Col md={6}>
              <img src="../image/payment-cancel.png" class="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
  </>
  )
}
