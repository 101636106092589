import React, { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Col, Button, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ModalBox } from "../Outer/Modal";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import { CloseRounded } from "@mui/icons-material";
import { MultiSelect } from 'react-multi-select-component';
import {
  AuctionAll,
  SportAllMap,
  BidStatus,
  RefreshToken,
  SubscribeManager,
  ManagerGetAll,
  PlayerGetSubscribeManager
} from "../../redux/auth-reducer/auth-service";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import * as signalR from "@microsoft/signalr";
import { ToastContainer, toast } from 'react-toastify';

function AuctionPlayer() {
  const [data, setData] = useState(false);
  const [searchTextClose, setSearchTextClose] = useState("");
  const [searchTextUpcomingLive, setSearchTextUpcomingLive] = useState("");
  const [searchTextUpcomingLiveAuction, setSearchTextUpcomingLiveAuction] = useState("");
  const [searchTextCloseAuction, setSearchTextCloseAuction] = useState("");
  const [showView, setShowView] = useState(false);
  const [itemlist, setitemList] = useState([]);
  const [itemsport, setitemSport] = useState([]);
  const [count, setCount] = useState(0);
  const [timestamp, setTimestamp] = useState();
  const [filterUpcomingLiveAuction, setFilterUpcomingLiveAuction] = useState([]);
  const [filterClosedAuction, setFilterClosedAuction] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [hubConnection, setHubConnection] = useState(null);
  const [signalRData, setSignalRData] = useState([]);
  const [BidStatusAuction, setBidStatusAuction] = useState([]);
  const [allManager, setAllManager] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [subscribedManagers, SetSubscribedManagers] = useState([]);

  const isUser = JSON.parse(localStorage.getItem('isUser'));
  const userId = isUser?.userId;

  const navigate = useNavigate();
  const sidebarfun = () => {
    setData(!data);
  };

  useEffect(() => {
    async function apiCalling() {

      let temp_sports = await SportAllMap();
      setitemSport(temp_sports?.data?.data?.dataList);

    }
    apiCalling();
  }, []);

  const BidAllFn = async (data) => {
    await BidStatus(data).then((response) => {

      setitemList(response?.data);

    })
  }

  useEffect(() => {
    const getSubscribeManager = async () => {
      await PlayerGetSubscribeManager(userId).then((response) => {

        SetSubscribedManagers(response?.data)
      })
    }
    getSubscribeManager()
  }, []);

  useEffect(() => {
    const BidStatusAll = async () => {
      await AuctionAll().then((res) => {

        const selectedLabels = selectedOptions?.map(option => option.label).filter(label => label);

        const filteredData = res?.data.filter(item => {

          const match = selectedLabels?.includes(item.createdByName);

          return match;
        });

        setBidStatusAuction(filteredData);
      });
    }
    BidStatusAll()
  }, [selectedOptions])

  useEffect(() => {
    const getAllManagers = async () => {
      await ManagerGetAll().then((res) => {

        setAllManager(res?.data?.data?.dataList);
      });
    }
    getAllManagers()
  }, [])

  const approvedManager = subscribedManagers?.filter(manager => manager.status === "Approved");

  const managerNames = approvedManager?.map(manager => ({
    label: manager?.managerName,
    value: manager?.managerId
  }));

  useEffect(() => {

    if (JSON.stringify(selectedOptions) !== JSON.stringify(managerNames)) {
      setSelectedOptions(managerNames);
    }
  }, [managerNames]);

  useEffect(() => {
    const data = {
      "PageNumber": 1,
      "PageSize": perPage
    }
    BidAllFn(data);

  }, [count]);




  var stDate, stTime;
  if (timestamp) {
    [stDate, stTime] = timestamp.split("T");
  } else {
    stDate = "";
    stTime = "";
  }

  const convertUTCToLocalTime = (utcDateString) => {
    const date = new Date(utcDateString);
    return date.toLocaleString();
  };
  const columns = [

    {
      name: "Team Name",
      selector: (row) => row?.teamName,
      sortable: true,
    },
    {
      name: "Auction Name",
      selector: (row) => row?.auctionName,
      sortable: true,
    },
    {
      name: "Tournament Name",
      selector: (row) => row?.tournamentName,
      sortable: true,
    },
    {
      name: "Sport Name",
      selector: (row) => row?.sportsName,
      sortable: true,
    },

    {
      name: "Start Date",
      selector: (row) => dayjs(row?.startDate).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: <strong>Start Time</strong>,

      selector: (row) => {
        const localTime = convertUTCToLocalTime(row?.startDate + "Z");
        const [datePart, timePart] = localTime.split(", ");
        return (
          <p>{timePart}</p>
        );
      },
      sortable: true,
    },
    {
      name: "Bid Time Limit",
      selector: (row) => row?.bidTime,
      sortable: true,
    },

    {
      name: "Secondary Market",
      selector: (row) =>
        row.secondaryMarket == true ? (
          <div className="bg-succ">Available</div>
        ) : (
          <div className="bg-dang">Unavailable</div>
        ),
        width:"150px",
      sortable: true,
    },
    {
      name: "Starting Bid",
      selector: (row) => row?.startingBid,
      sortable: true,
    },
    {
      name: "Bid Increment",
      selector: (row) => row?.bidIncrement,
      sortable: true,
    },

    {
      name: "Bid Status",
      selector: (row) =>
        row.status === "Upcoming" ? (
          <>
            <span style={{ color: "#f9c114" }}>Upcoming</span>
          </>
        ) : row.status === "Live" ? (
          <>
            <span style={{ color: "#043bc7" }}>Live</span>
          </>
        ) : row.status === "Closed" ? (
          <>
            <span style={{ color: "red" }}>Closed</span>
          </>
        ) : row.status === "Paused" ? (
          <>
            <span style={{ color: "red" }}>Paused</span>
          </>
        ) : (
          <>
            <span style={{ color: "green" }}>Play</span>
          </>
        ),

      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row?.createdByName,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <>
          {row?.status === "Live" ? <button
            className="tablebtn placebid"

            onClick={() => handleSubmit(row)}
          >
            Place Bid
          </button> :
            <button
              className="tablebtn placebid disable"
              disabled
              onClick={() => handleSubmit(row)}

            >
              Place Bid
            </button>
          }

        </>
      ),
      sortable: true,
    },

  ];
  const handleSubmit = (item) => {
    navigate(`/Player/PlaceBid?auctionId`, { state: item });
  };

  useEffect(() => {
    if (isButtonDisabled) {
      setIsButtonDisabled(true);
    }
  }, [isButtonDisabled])

  const handleClosed = () => {
    setShowView(false);
  };

  const handleSearchClose = (e) => {
    setSearchTextClose(e.target.value);
  };
  const handleSearchUpcomingLive = (e) => {
    setSearchTextUpcomingLive(e.target.value);
  };

  const handleSearchCloseAuction = (e) => {
    setSearchTextCloseAuction(e.target.value);
  };
  const handleSearchUpcomingLiveAuction = (e) => {
    setSearchTextUpcomingLiveAuction(e.target.value);
  };

  useEffect(() => {

    const filterUpcomingsLivesAuction = (eventArray) => {

      return eventArray?.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused");
    };

    setFilterUpcomingLiveAuction(filterUpcomingsLivesAuction(BidStatusAuction));

    const filterClosesAuction = (eventArray) => {

      return eventArray?.filter((event) => event.status === "Closed" || event.status === "Archived");
    };

    setFilterClosedAuction(filterClosesAuction(BidStatusAuction));

  }, [signalRData, BidStatusAuction]);

  const filteredDataUpcomingLiveAuction = searchTextUpcomingLive ? filterUpcomingLiveAuction.filter((item) => {
    return item?.auctionName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.sportsName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.status.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase())

  }) : filterUpcomingLiveAuction;

  const filteredDataCloseAuction = searchTextClose ? filterClosedAuction.filter((item) => {
    return item?.auctionName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item.sportsName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextClose.toLowerCase()) || item?.status?.toLowerCase().includes(searchTextClose?.toLowerCase())
  }) : filterClosedAuction;


  useEffect(() => {
    if (signalRData && BidStatusAuction) {
      const updatedAuctionData = BidStatusAuction?.map((auction) => {
        const matchingSignalRAuction = signalRData?.find((signalRAuction) => signalRAuction?.auctionId === auction?.auctionId && signalRAuction?.teamId === auction?.teamId);
        if (matchingSignalRAuction) {
          auction.currentBidAmount = matchingSignalRAuction?.currentBidAmount;
          auction.startingBid = matchingSignalRAuction?.startingBid;
          auction.status = matchingSignalRAuction?.status;
        }
        return auction;
      });
      setBidStatusAuction(updatedAuctionData);
    }
  }, [signalRData]);

  useEffect(() => {
    const createHubConnection = async () => {
      let token = localStorage.getItem('isToken');
      const hubConnect = new signalR.HubConnectionBuilder()
        .withUrl("https://staging.xcutta.com/backend/hubs", {
          // https://staging.xcutta.com/backend/hubs
          // https://gadienterprises-gamc-qa.chetu.com/hubs
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .build();

      const handleTokenExpiration = async () => {

        const newToken = await RefreshToken();
        token = newToken?.data?.token;
        hubConnect.accessTokenFactory = () => token;
      };

      try {
        await hubConnect.start();

      } catch (err) {

        await handleTokenExpiration();
        await hubConnect.start();

      }

      setHubConnection(hubConnect);
    };

    createHubConnection();
  }, []);

  useEffect(() => {

    if (hubConnection) {
      hubConnection.on("ReceiveBidStatuses", (data) => {
        setSignalRData(data);
        console.log(data, "datadata");

      });
    }
    else {

    }
  }, [hubConnection]);

  const options = allManager?.map(manager => ({
    label: `${manager?.firstName} ${manager?.lastName}`,
    value: manager?.managerId
  }));

  const handleRequest = async (managerId) => {
    await SubscribeManager(userId, managerId).then((res) => {

      if (res?.data?.status == true == true) {
        toast.success(res.data?.message);
      } else {
        toast.error(res.data?.message);
      }
    });
  };

  const handleChange = (selected) => {

    const addedOptions = selected?.filter(option => !selectedOptions?.some(selectedOption => selectedOption?.value === option?.value));
    const removedOptions = selectedOptions?.filter(option => !selected?.some(selectedOption => selectedOption?.value === option?.value));

    setSelectedOptions(selected);

    addedOptions.forEach(option => {
      handleRequest(option.value);
    });

    removedOptions?.forEach(option => {

      handleRequest(option.value);
    });
  };

  return (
    <>

      <ToastContainer />

      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group">
        <Sidebar data={data} />
        <div
          className={
            data ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >

          


          <Row className="select-dropdown-manager">
                  
                  <Col md={6} ><h1 className="heading-dash">Auction</h1></Col>
                  <Col md={6}  >
                
                  </Col>
                </Row>

          <div className="group_data_table tabs-auction ">
           
     
            <div className="tabs-box">
              
            
              {
                managerNames?.length == 0 ? (<>
                  <h4>Add Manager To See Auctions</h4>

                  <Form.Group as={Col} md="2 mt-3" className="text-left mt-2" controlId="validationCustomOption">
                    <Form.Label>Select Manager</Form.Label>
                    <MultiSelect
                      options={options || []}
                      value={selectedOptions || []}
                      onChange={handleChange}
                      labelledBy={"Select"}
                      required
                    />
                  </Form.Group>
                </>
                ) : (
                  <>
                  <Row>
          
          <Col md={2} className="dropdown-manager-approve">
            <Form.Group  controlId="validationCustomOption">
                          <Form.Label>Select Manager</Form.Label>
                          <MultiSelect
                            options={options || []}
                            value={selectedOptions || []}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            required
                          />
                        </Form.Group>
            </Col>
      </Row>
                  <Tabs
                    defaultActiveKey="profile"
                    id="auctiontabs"
                    className="mb-0 tab-list-item player"
                    justify
                  >
                    
                    <Tab eventKey="profile" title="Live and Upcoming">
                      <div>
                        <div className="auctiontabs">
                         {
                          filteredDataUpcomingLiveAuction.length==0 ? "" :
                          <div className="search-input filter auction-filter palyer">
                         
                          <Form.Group as={Col} md="6 mt-3 me-3" className="text-left  mt-2" controlId="validationCustomOption">
                            <Form.Label className="search-player-auction">Search</Form.Label>
                            <input
                              type="text"
                              className="form-control mb-3 mt-3"
                              placeholder="Search..."
                              value={searchTextUpcomingLive}
                              onChange={handleSearchUpcomingLive}
                            />
                          </Form.Group>
                        </div>
                         }
                          

                          <DataTable
                            columns={columns}
                            data={filteredDataUpcomingLiveAuction}
                            pagination
                            paginationPerPage={10}
                            defaultSortFieldId={1}
                            paginationRowsPerPageOptions={[10, 20, 30]}
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Closed" title="Closed">
                      <div>
                        <div className="auctiontabs">
                          <div className="search-input filter auction-filter">
                            <input
                              type="text"
                              className="form-control mb-3 mt-3"
                              placeholder="Search..."
                              value={searchTextClose}
                              onChange={handleSearchClose}
                            />
                          </div>

                          < DataTable
                            columns={columns}
                            data={filteredDataCloseAuction}
                            pagination
                            paginationPerPage={10}
                            defaultSortFieldId={1}
                            paginationRowsPerPageOptions={[10, 20, 30]}
                          />
                        </div>
                      </div>
                      
                    </Tab>
                
                  </Tabs>
                  </>
                )

              }

            </div>

          </div>

        </div>
        <Footer />
      </div>

      <ModalBox
        show={showView}
        title={
          <>
            <h1 className="heading-dash">Team Detail</h1>
          </>
        }
        body={
          <>
            <Table bordered hover className="table-team border">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Team Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <Image src="/image/team-icon.png" width={50} />
                  </td>
                  <td>Team 1</td>
                </tr>
              </tbody>
            </Table>
          </>
        }
        footer={
          <>
            <Button variant="secondary close-btn" onClick={handleClosed}>
              <CloseRounded />
            </Button>
          </>
        }
      />
    </>
  );
}

export default AuctionPlayer;







