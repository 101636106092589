import Modal from 'react-bootstrap/Modal';
import React from 'react'

export const ModalBox = ({ show, onHide, title, body, footer, showModal, showDelete }) => {
  return (

    <>
      <Modal size="lg" show={show} onHide={onHide} centered>
        <Modal.Header >
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
        <Modal.Footer>
          {footer}
        </Modal.Footer>

      </Modal>




      <Modal show={showModal} onHide={onHide}>

        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>

      </Modal>



    </>
  )
}

export const ModalDelete = ({ show, onHide, title, body, footer, }) => {
  return (
    <>
      <Modal size="sm" show={show} onHide={onHide} centered>
        <Modal.Header >
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      </Modal>
    </>
  )
}
