import React from 'react'
import Headertwo from './Headertwo'


export const Features = () => {
  return (
    <>
    <div className='group-section'>
     <Headertwo />
     <div className='banner-item'>
     <h1>Coming Soon</h1>
      </div>
      </div>
    </>
  )
}
