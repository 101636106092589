import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import "../../assets/css/scss/main.css";
import RightSideBanner from "./RightSideBanner";
import { useNavigate } from "react-router-dom";
import {
  EmailCheck,
  OragnizationCity,
  OragnizationCounrty,
  OragnizationStates,
  OrganizationReg,
} from "../../redux/auth-reducer/auth-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as icons from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import "react-phone-number-input/style.css";


function Organization() {
  const [validated, setValidated] = useState(false);
  const [counrtyData, setCounrtyData] = useState();
  const [OrgcounrtyData, setOrgCounrtyData] = useState();
  const [orgSelectedCountry, setOrgSelectedCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [orgStates, setOrgStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [orgselectedStates, setOrgSelectedStates] = useState([]);
  let [getcity, setGetcity] = useState([]);
  let [city, setCity] = useState([]);
  let [orgGetCity, setOrgGetCity] = useState([]);
  let [orgCity, setOrgCity] = useState([]);
  const [smShow, setSmShow] = useState(false);
  let [visible, setVisible] = useState(false);
  let [visibleIcon, setVisibleIcon] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isPlayer, setIsPlayer] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errMessage, setErrMessage] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [regForm, setRegForm] = useState({
    emailId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    isPlayer: "",
    orgName: "",
    orgEmailId: "",
    orgAddress: "",
    orgCountry: "",
    orgState: "",
    orgCity: "",
    orgZipCode: "",
    password: "",

    confirmPassword: "",
  });
  const [err, setErr] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function counrtyDataList() {
      await OragnizationCounrty().then((res) => {
        setCounrtyData(res?.data);
        setOrgCounrtyData(res?.data);
      });
    }
    counrtyDataList();
  }, []);

  const handleSubmit = async (event) => {
    setRegForm("");

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    const data = {
      crudOperationId: true,
      id: 0,
      emailId: regForm?.emailId,
      firstName: regForm?.firstName,
      lastName: regForm?.lastName,
      phoneNumber: regForm?.phoneNumber,
      address: regForm?.address,
      country: location?.country,
      state: location?.['places'][0]['state'],
      city: location?.['places'][0]['place name'],
      zipCode: regForm?.zipCode,
      isPlayer: isPlayer,
      orgName: regForm?.orgName,
      orgEmailId: regForm?.orgEmailId,
      orgAddress: regForm?.orgAddress,
      orgCountry: locationOrg?.country,
      orgState: locationOrg?.['places'][0]['state'],
      orgCity: locationOrg?.['places'][0]['place name'],
      orgZipCode: regForm?.orgZipCode,
      isPrivate: isVisible,
      isActive: true,
      password: regForm?.password,
      confirmPassword: regForm?.confirmPassword,
    };



    var mailformat = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;

    if (regForm?.emailId?.match(mailformat)) {
      setErrMessage(false);
      try {
        await OrganizationReg(data).then((res) => {
          if (res?.status) {
            setSmShow(true);

          } else {
            toast.error("Please Fill the Field");
          }
        });
      } catch (error) {

        toast.error(error?.response?.data?.title);
      }
    } else {
      setErrMessage(true);
    }
  };
  const handelform = async (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value.trim(),
    });
    setErrMessage(true);

  };

  const handelformPass = (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value.trim(),
    });
    setIsValid(isPasswordValid(e.target.value));
  };
  const isPasswordValid = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;
    return passwordRegex.test(password);
  };



  const handelOrgCounrty = (e) => {
    setOrgSelectedCountry(e.target.value);
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    async function selctCountry() {
      if (orgSelectedCountry != "") {
        await OragnizationStates(orgSelectedCountry).then((res) => {

          setOrgStates(res?.data);
        });
      }
    }
    selctCountry();
  }, [orgSelectedCountry]);


  useEffect(() => {
    async function selectCity() {
      if (orgselectedStates != "") {
        await OragnizationCity(orgselectedStates).then((res) => {

          setOrgCity(res?.data);
        });
      }
    }
    selectCity();
  }, [orgselectedStates]);


  useEffect(() => {
    async function selctCountry() {
      if (selectedCountry != "") {
        await OragnizationStates(selectedCountry).then((res) => {

          setStates(res?.data);
        });
      }
    }
    selctCountry();
  }, [selectedCountry]);


  useEffect(() => {
    async function selectCity() {
      if (selectedStates != "") {
        await OragnizationCity(selectedStates).then((res) => {

          setCity(res?.data);
        });
      }
    }
    selectCity();
  }, [selectedStates]);



  async function selectEmail(email) {
    const data = {
      email: email,
    };
    await EmailCheck(data).then((res) => {
      if (res?.data?.status == true) {
        toast.error(res?.data?.message);
      } else {

      }
    });
  }

  const handelEmail = (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value,
    });
    const value = e.target.value;

    const EmailExist = value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (EmailExist) {
      selectEmail(value);
    } else {

      return;
    }
  };



  const RequestDemo = () => {
    navigate("/Login");
  };

  const handelVisible = () => {
    setIsPlayer(!isPlayer);
  };
  const handelVisiblesec = () => {
    setIsVisible(!isVisible);
  };
  const show = (event) => {
    setVisible(!visible);
  };
  const showIcon = () => {
    setVisibleIcon(!visibleIcon);
  };
  useEffect(() => {
    if (
      regForm?.password !== regForm?.confirmPassword &&
      regForm?.password?.length > 0 &&
      regForm?.confirmPassword?.length > 0
    ) {
      setErr("Password and confirmPassword does not match");
    } else {
      setErr("");
    }
  }, [regForm?.password, regForm?.confirmPassword]);

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    }
  };
  const backBtn = () => {
    navigate(-1);
  };

  const handleSameDetail = () => {
    setChecked(!checked);
  };

  useEffect(() => {

    if (checked) {
      setRegForm((prevValue) => ({
        ...prevValue,
        orgAddress: prevValue?.address || "",
        orgCountry: prevValue?.country || "",
        orgState: prevValue?.state || "",
        orgCity: prevValue?.city || "",
        orgZipCode: prevValue?.zipCode || "",
      }));

    } else {
      setRegForm((prevValue) => ({
        ...prevValue,
        orgAddress: "",
        orgCountry: "",
        orgState: "",
        orgCity: "",
        orgZipCode: "",
      }));
    }

  }, [checked]);



  const [location, setLocation] = useState(null);
  const [locationOrg, setLocationOrg] = useState(null);
  const [error, setError] = useState('');

  const handelZipCode = async (e) => {
    const zipCode = e.target.value;
    setRegForm({
      ...regForm,
      [e.target.name]: zipCode,
    },)
    if (zipCode.length == 5) {
      try {
        const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
        if (!response.ok) {
          throw new Error('Zip code not found');
        }
        const data = await response.json();
        setLocation(data);
        setError('');
      } catch (err) {
        setError(err.message);
        setLocation(null);
      }
    }
  }



  const handelZipCodeOrg = async (e) => {
    const zipCodeOrg = e.target.value;
    setRegForm({
      ...regForm,
      [e.target.name]: zipCodeOrg,
    },)
    if (zipCodeOrg.length == 5) {
      try {
        const response = await fetch(`https://api.zippopotam.us/us/${zipCodeOrg}`);
        if (!response.ok) {
          throw new Error('Zip code not found');
        }
        const data = await response.json();
        setLocationOrg(data);
        setError('');
      } catch (err) {
        setError(err.message);
        setLocationOrg(null);
      }
    }
  }



  return (
    <>
      <Modal show={smShow} centered>
        <div className="group-modal">
          <img src="./image/popup-box.svg" className="img-fluid" />
          <div className="icons-check">
            <img src="./image/check.svg" />
          </div>
        </div>
        <Modal.Body className="thankyouModal">
          <h2 className="text-center p-2 mt-4">
            <b>Thank you</b>
          </h2>
          <p className="text-center">You are successfully registered</p>
          <div className="text-center">
            <Button
              type="button"
              onClick={RequestDemo}
              className="button_back_login"
            >
              {" "}
              <b>Go To Login</b>
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
      <div className="grop-section">
        <div className="icon-back">
          <icons.ArrowLeft onClick={backBtn} />
        </div>
        <div className="group-reg">
          <div className="item">
            <div className="inner-item">
              <div className="form-contnet">
                <h1 className="heading">Create a new account </h1>

                <p></p>
              </div>
              <h3>
                <b>Manager Info</b>
              </h3>
              <div className="line"></div>

              <Form
                noValidate
                validated={validated}
                className="registration"
                onSubmit={handleSubmit}
              >
                <label className="d-flex text-color-la">
                  <input
                    type="checkbox"
                    className="inputCheck"
                    onClick={handelVisible}
                    name="isPlayer"
                    value={isPlayer}
                    disabled={disabled}
                  />
                  <span class="checkmark"></span>
                  Do you want manager can bid like as player?
                </label>
                <Row className="mb-3 mt-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustomFristname"
                  >
                    <Form.Label>First name</Form.Label>{" "}
                    <span
                      style={{
                        color: "#f44336",
                        fontSize: "20px",
                        marginLeft: "-1px",
                      }}
                    >
                      *
                    </span>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="firstName"
                        placeholder="Enter your first name"
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a Frist name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    className="text-left"
                    controlId="validationCustomLastname"
                  >
                    <Form.Label>Last name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="lastName"
                        placeholder="Enter your last name"
                        aria-describedby="inputGroupPrepend"

                      />

                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustomPhoneNumber"
                  >
                    <Form.Label>Phone number</Form.Label>
                    <span
                      style={{
                        color: "#f44336",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </span>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        onChange={handelform}
                        onKeyDown={handleKeypress}
                        maxlength="10"
                        pattern="[1-9]{1}[0-9]{9}"
                        value={regForm?.phoneNumber}
                        placeholder="Enter your phone number"
                        aria-describedby="inputGroupPrepend"
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter a phone number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="6" className="text-left">
                    <Form.Label>Email</Form.Label>
                    <span
                      style={{
                        color: "#f44336",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </span>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="email"
                        onChange={handelEmail}
                        name="emailId"
                        maxlength="50"
                        value={regForm?.emailId}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter your email"
                        autocomplete="off"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a Email.
                      </Form.Control.Feedback>

                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    className="text-left"
                    controlId="validationCustomCompanyAddress"
                  >
                    <Form.Label>Address</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="address"
                        placeholder="Enter your address"
                        aria-describedby="inputGroupPrepend"
                        required

                      />

                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    className="text-left"
                    controlId="validationCustomZipCode"
                  >
                    <Form.Label>Zip/Province code</Form.Label>

                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          handelZipCode(e);
                        }}

                        name="zipCode"
                        placeholder="Enter zip code"
                        aria-describedby="inputGroupPrepend"
                        maxLength="10"

                      />

                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    className="text-left"
                    controlId="validationCustomCountry"
                  >
                    <Form.Label>Country</Form.Label>

                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="country"
                        maxlength="50"
                        value={location?.country}

                        onKeyDown={handleKeyDown}
                        placeholder="Enter your country"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a country.
                      </Form.Control.Feedback>
                    </InputGroup>

                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="text-left"
                    controlId="validationCustomState"
                  >
                    <Form.Label>State/Province</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="state"
                        maxlength="50"
                        value={location?.['places'][0]['state']}

                        onKeyDown={handleKeyDown}
                        placeholder="Enter your state"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a state.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="6" className="text-left">
                    <Form.Label>City name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="city"
                        maxlength="50"
                        value={location?.['places'][0]['place name']}

                        onKeyDown={handleKeyDown}
                        placeholder="Enter your city"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a city.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Password</Form.Label>
                    <span
                      style={{
                        color: "#f44336",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </span>
                    <InputGroup hasValidation>
                      <Form.Control
                        type={visible ? "text" : "password"}
                        onChange={handelformPass}
                        value={regForm?.password}
                        name="password"
                        maxLength={20}
                        placeholder="Enter password"
                        aria-describedby="inputGroupPrepend"
                        isInvalid={!isValid}
                        required
                      />
                      <div className="eye-icon" onClick={show}>
                        {visible ? <icons.Eye /> : <icons.EyeSlash />}
                      </div>

                      <Form.Control.Feedback type="invalid">
                        Password must be at least 6 characters long and contain
                        at least one uppercase letter, one lowercase letter, one
                        number, and one special character.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Confirm password</Form.Label>
                    <span
                      style={{
                        color: "#f44336",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </span>
                    <InputGroup hasValidation>
                      <Form.Control
                        type={visibleIcon ? "text" : "password"}
                        onChange={handelform}
                        value={regForm?.confirmPassword}
                        maxLength={20}
                        name="confirmPassword"
                        placeholder="Enter confirm password"
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <div className="eye-icon" onClick={showIcon}>
                        {visibleIcon ? <icons.Eye /> : <icons.EyeSlash />}
                      </div>
                      {err ? (
                        <p className="err-message">
                          Password and confirm Password does not match
                        </p>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          Please Enter a Confirm Password
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <label className="d-flex text-color-la">
                      <input
                        type="checkbox"
                        className="inputCheck"
                        onClick={handelVisiblesec}
                        name="isPrivate"
                        value={isVisible}
                      />
                      <span class="checkmark"></span>
                      Do you want to keep Company info private?
                    </label>

                    <h3 className="mt-3">
                      <b>Company Info</b>
                    </h3>
                    <div className="line"></div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12 mt-4"
                    className="text-left"
                    controlId="validationCustomCompanyName"
                  >
                    <Form.Label>Company name</Form.Label>
                    <span
                      style={{
                        color: "#f44336",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </span>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="orgName"
                        placeholder="ABC"
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a Auctioneer name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    className="text-left"
                    controlId="validationCustomEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <span
                      style={{
                        color: "#f44336",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </span>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="email"
                        onChange={handelEmail}
                        name="orgEmailId"
                        maxlength="50"
                        value={regForm?.orgEmailId}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter your email"
                        aria-describedby="inputGroupPrepend"
                        autocomplete="off"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a Email.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    className="text-left"
                    controlId="validationCustomCompanyAddress"
                  >
                    <label
                      className="d-flex text-color-la"
                      style={{ marginTop: "15px" }}
                    >
                      <input
                        type="checkbox"
                        className="inputCheck"
                        onClick={handleSameDetail}
                        name="sameAdressCheck"
                        checked={checked}

                      />
                      <span class="checkmark"></span>
                      Do you want to fill the below address details same as
                      above
                    </label>
                    <Form.Label>Address</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="orgAddress"
                        placeholder="Enter your company address"
                        aria-describedby="inputGroupPrepend"

                        value={regForm?.orgAddress}
                      />

                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="text-left"
                    controlId="validationCustomZipCode"
                  >

                    <Form.Label>Zip/Province code</Form.Label>

                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          handelZipCodeOrg(e);
                        }}
                        value={regForm?.orgZipCode}
                        name="orgZipCode"
                        maxLength="10"
                        placeholder="Enter zip code"
                        aria-describedby="inputGroupPrepend"

                      />

                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="text-left"
                    controlId="validationCustomCountry"
                  >
                    <Form.Label>Country</Form.Label>

                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="orgCountry"
                        maxlength="50"
                        value={locationOrg?.country}
                        // value={regForm?.orgCountry}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter your country"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a country.
                      </Form.Control.Feedback>
                    </InputGroup>

                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="text-left"
                    controlId="validationCustomState"
                  >
                    <Form.Label>State/Province</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="orgState"
                        maxlength="50"
                        value={locationOrg?.['places'][0]['state']}
                        // value={regForm?.orgState}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter your state"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a state.
                      </Form.Control.Feedback>
                    </InputGroup>

                  </Form.Group>

                  <Form.Group as={Col} md="6" className="text-left">
                    <Form.Label>City name</Form.Label>

                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        onChange={handelform}
                        name="orgCity"
                        maxlength="50"
                        value={locationOrg?.['places'][0]['place name']}
                        // value={regForm?.orgCity}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter your city"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a city.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Col md="12 submit_button">
                    <Button type="submit" className="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
              <p className="account-para">
                Already have an account? <a href="/Login">Login here</a>
              </p>
            </div>
          </div>
          <RightSideBanner />
        </div>
      </div>
    </>
  );
}
export default Organization;


