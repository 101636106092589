import { configureStore } from '@reduxjs/toolkit'
import counterReducer from "../auth-reducer/slice";
import authReducer from '../auth-reducer/auth-reducer';

export const Store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,

  },
})