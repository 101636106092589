import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import '../../assets/css/scss/main.css';
import RightSideBanner from './RightSideBanner';

function ForgetEmail() {
    return (
        <>
            <div className='group-reg'>
                <div className='item'>
                    <div className='inner-item'>
                        <div className='form-contnet'>
                            <img src="./image/logo.svg" />
                            <h1 className='heading'>Verify Your Email</h1>
                            <p>Please enter the 6 digit Code sent to jhondoe@gmail.com</p>
                        </div>

                        <Row className="mb-3">
                            <Form.Control className='otp-input'
                                type="text"
                                aria-describedby="inputGroupPrepend"
                            />
                            <Form.Control className='otp-input'
                                type="text"

                                aria-describedby="inputGroupPrepend"

                            />
                            <Form.Control className='otp-input'
                                type="text"

                                aria-describedby="inputGroupPrepend"

                            />
                            <Form.Control className='otp-input'
                                type="text"

                                aria-describedby="inputGroupPrepend"

                            />
                            <Form.Control className='otp-input'
                                type="text"

                                aria-describedby="inputGroupPrepend"

                            />
                            <Form.Control className='otp-input'
                                type="text"

                                aria-describedby="inputGroupPrepend"

                            />


                            <Col md="12 mt-3">
                                <Button type="submit" className='submit'>Continue</Button>
                            </Col>


                            <p className='account-para mt-3'>Don't have an Account? <a href="/Organization">Please resend</a></p>
                        </Row>


                    </div>
                </div>
                <RightSideBanner />
            </div>
        </>)
}
export default ForgetEmail;