import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { ToastContainer } from 'react-toastify';
function RightSideBanner() {
  const [smShow, setSmShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState('UserRegistration');
  const navigate = useNavigate();
  const handleShow = () => setSmShow(true);
  const handleClose = () => setSmShow(false);
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  function subBtn() {
    if (selectedOption === 'UserRegistration') {
      navigate('/UserRegistration')
    } else if (selectedOption === 'Organization') {
      navigate('/Organization')
    }

  }
  const close = () => {
    setSmShow(false)
  }
  return (
    <>
      <ToastContainer></ToastContainer>
      <Modal show={smShow} onHide={handleClose} centered>


        <img src="../image/circle_full_width.png" width="100%" height={80} />

        <Modal.Body className='pt-0'>
          <h2 className='text-center heading-get-started'><b>Get Started Now</b></h2>
          <p className='text-center mb-4 mt-3'>Create an Account as</p>

          <div className='grop-input-radio d-flex justify-content-center'>
            <Form.Check className="me-3" label=" Player" type="radio" name="flexRadioDefault" value="UserRegistration"
              checked={selectedOption === 'UserRegistration'} onChange={handleOptionChange} id="flexRadioDefault"
            />


            <Form.Check label="Xcutta Manager" type="radio" name="flexRadioDefault" value="Organization" checked={selectedOption === 'Organization'}
              onChange={handleOptionChange} id="flexRadioDefault2"
            />
          </div>
          <Row>

            <Col md={12} className='text-center mt-4 pb-5'>
              <Button className='text-center submit-btn' type="submit" onClick={subBtn}>Continue</Button>
              <Button className='text-center btn btn-danger  ms-3' onClick={close} type="submit"  >Close</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <div className='item-bg-login'>
     

        <div className='banner-content text-center'>
          <div>
            <Link to="/Home"> <img src='../image/logo.svg' /></Link>

            <h1>PLAY AND WIN </h1><br /><br />
            <img src='../image/button.png' onClick={handleShow} />
          </div>
        </div>

      </div>
    </>
  )
}

export default RightSideBanner