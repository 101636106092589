import React, { useEffect, useState } from 'react'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import { Footer } from '../../Footer';
import DataTable from 'react-data-table-component';
import { Form } from 'react-bootstrap';
import { BidAll, BidWinnerList, ManagerGetAll } from '../../redux/auth-reducer/auth-service';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export const Bid = () => {
    const [data, setData] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchTextWin, setSearchTextWin] = useState('');
    const [itemList, setItemList] = useState();
    const [itemDataBidWin, setItemDataBidWin] = useState([])
    const [listBid, setListBid] = useState([])
    const [allManager, setAllManager] = useState([]);
    const [selectedManagerId, setSelectedManagerId] = useState('');
    const [filteredData, setFilteredData] = useState(itemList);
    const [activeTab, setActiveTab] = useState("BidAlll");

    // console.log(state,">>>>>>>>>>>>>>>???????????")

    const sidebarfun = () => {
        setData(!data)
    }
    const handleSelectChange = (event) => {
        setSelectedManagerId(event.target.value);
    };

    useEffect(() => {
        const getAllManagers = async () => {
            await ManagerGetAll().then((res) => {
                setAllManager(res?.data?.data?.dataList);
            });
        }
        getAllManagers()
    }, [])

    const columns = [

        {
            name: 'Id',
            selector: (row, index) => index + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: 'Auction Name',
            selector: (row) => row?.auctionName,
            sortable: true,
        },
        {
            name: 'Team Name',
            selector: (row) => row?.teamName
            ,
            sortable: true,
        },

        {
            name: 'Bid Time Limit',
            selector: (row) => row?.bidTime,
            sortable: true,
        },
        {
            name: 'Starting Bid',
            selector: (row) => row?.startingBid,
            sortable: true,
        },
        {
            name: 'Bid Increment',
            selector: (row) => row?.bidIncrement,
            sortable: true,
        },
        {
            name: 'Current Bid',
            selector: (row) => row?.currentBidAmount,
            sortable: true,
        },
        {
            name: 'Auction Created By',
            selector: (row) => row?.createdByName,
            sortable: true,
        },
        {
            name: 'Total Bids',
            selector: (row) => row?.totalPlacedBids,
            sortable: true,
        },

    ];

    const columns2 = [

        {
            name: 'Id',
            selector: (row, index) => index + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: 'Auction Name',
            selector: (row) => row?.auctionName,
            sortable: true,
        },
        {
            name: 'Team Name',
            selector: (row) => row?.teamName
            ,
            sortable: true,
        },
        {
            name: 'Bid Amount',
            selector: (row) => row?.bidAmount
            ,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row?.status
            ,
            sortable: true,
        },

    ];

    useEffect(() => {

        const TeamAllFn = async () => {
            await BidAll().then((response) => {

                setItemList(response?.data?.data?.dataList);
                setListBid(response?.data?.data?.dataList)
            })
        }
        TeamAllFn();

    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleSearchWin = (e) => {
        setSearchTextWin(e.target.value);
    };

    useEffect(() => {
        const filteredItems = itemList?.filter(item => {
            const matchesManager = selectedManagerId ? item?.createdBy === parseInt(selectedManagerId) : true;
            const matchesSearchText = searchText
                ? item?.auctionName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                item?.teamName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                item?.createdByName?.toLowerCase().includes(searchText?.toLowerCase())
                : true;

            return matchesManager && matchesSearchText;
        });

        setFilteredData(filteredItems);
    }, [itemList, searchText, selectedManagerId]);

    useEffect(() => {

        const BidWinFn = async () => {
            await BidWinnerList().then((response) => {
                console.log(response?.data, "??????????????????")
                setItemDataBidWin(response?.data);
            })

        }
        BidWinFn();

    }, []);

    const filteredDataWin = searchTextWin ? itemDataBidWin.filter((item) => {
        return item?.auctionName?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item?.teamName?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item?.createdBy?.toLowerCase().includes(searchText?.toLowerCase())

    }) : itemDataBidWin;

    const handleSelect = (key) => {
        setActiveTab(key);
    };
    return (
        <>

            <TopNavbar sidebarfun={sidebarfun} />
            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Bid History</h1>

                    <div className='group_data_table'>
                        <div className="group_data_table tabs-auction bidAllData ">
                            <div className="tabs-box">
                                <Tabs
                                    activeKey={activeTab}
                                    id="auctiontabs"
                                    onSelect={handleSelect}
                                    className="mb-0 tab-list-item"
                                    justify
                                >
                                    <Tab eventKey="BidAlll" className='mt-3' title="ALL">
                                        <div>

                                            <div className='search-input bid-search filter d-flex align-items-center'>

                                                <label className='sort-status'>Filter By</label>

                                                <Form.Select name="gender" className='me-4' required as="select" onChange={handleSelectChange}>
                                                    <option>Select</option>
                                                    {allManager?.map(manager => (
                                                        <option key={manager?.managerId} value={manager?.managerId}>
                                                            {manager?.firstName} {manager?.lastName}
                                                        </option>
                                                    ))}

                                                </Form.Select >

                                                <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />
                                            </div>
                                            <DataTable
                                                title="dfsfd"
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 30]}
                                                paginationTotalRows={filteredData?.length}
                                            />

                                        </div>
                                    </Tab>
                                    <Tab eventKey="Winner" className='mt-3' title="Winner">
                                        <div className='search-input filter d-flex align-items-center'>
                                            <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchTextWin} onChange={handleSearchWin} />
                                        </div>
                                        <DataTable
                                            title="dfsfd"
                                            columns={columns2}
                                            data={filteredDataWin}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 20, 30]}
                                            paginationTotalRows={filteredDataWin?.length}
                                        />

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>

        </>

    )
}


