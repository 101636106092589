import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as icons from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { CheckoutAddPaymentStatus } from '../../../redux/auth-reducer/auth-service';
export const PaymentSuccess = () => {
  const navigate = useNavigate();
  let rolesd = localStorage.getItem('role');
  useEffect(() => {
    if (rolesd == "Player") {
      setTimeout(() => {
        navigate('/Player/Player_dashboard');
      }, 5000);
    }
    if (rolesd == "Manager") {
      setTimeout(() => {
        navigate('/SuperAdmin/SuperDashboard');
      }, 3000);
    }

  }, []);

  const value = localStorage.getItem('sesstionID');
 



  useEffect(() => {

    const PayementStatus = async () => {
      await CheckoutAddPaymentStatus(value).then((response) => {
        
      })
    }
    PayementStatus();
  }, [])

  return (


    <>
      <section className='payment-success'>
        <Container>
          <Row className='align-items-center justify-content-center'>
            <Col md={6} className='text-center'>
              <icons.Check2Circle className='payment-check' />
              <h2>Payment Successfully</h2>
              <p>The payment has been done successfully</p>
              <p>Thanks for being your with us</p>
            </Col>
            <Col md={6}>
              <img src="../image/succes-icon.png" class="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>

    </>
  )
}
