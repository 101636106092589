import React, { useState, useEffect } from "react";
import { CustomerAddPayment } from "../../../redux/auth-reducer/auth-service";

export const StripePayement = () => {
    const handelSubmit = async ()=>{
    await CustomerAddPayment().then((res)=>{
    
    })
    }
  return (
   <>
   <section>
    <div className="product">
      <img
        src="https://i.imgur.com/EHyR2nP.png"
        alt="The cover of Stubborn Attachments"
      />
      <div className="description">
      <h3>Stubborn Attachments</h3>
      <h5>$20.00</h5>
      </div>
    </div>
    <form onSubmit={handelSubmit}>
      <button type="submit">
        Checkout
      </button>
    </form>
  </section>
   </>
  )
}
const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );
  export default function App() {
    const [message, setMessage] = useState("");
  
    useEffect(() => {
      
      const query = new URLSearchParams(window.location.search);
  
      if (query.get("success")) {
        setMessage("Order placed! You will receive an email confirmation.");
      }
  
      if (query.get("canceled")) {
        setMessage(
          "Order canceled -- continue to shop around and checkout when you're ready."
        );
      }
    }, []);
  
    return message ? (
      <Message message={message} />
    ) : (
      <StripePayement />
    );
  }