import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { CheckoutAddPayment, UserGetId } from '../../redux/auth-reducer/auth-service';
import { useLocation } from 'react-router-dom';

export const Checkout = () => {
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState(false);
    const [userData, setUserData] =useState();
    const { state } = useLocation();
    const [formReg, setFormReg] =useState({
        name: "",
        email: "",
        customerId: "",
        currency: "",
        amount: 0,
        planType: "",
        description: ""
    })
    
    function sidebarfun() {
        setData(!data)
      }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setValidated(true);
       const data ={   
            "name": userData?.firstName,
            "email":userData?.emailId,
            "customerId": state?.id,
            "currency": "USD",
            "amount": formReg?.amount,
            "planType": formReg?.planType,
            "description": formReg?.description,
          }
          await CheckoutAddPayment(data).then((res)=>{
            let url=res?.data?.data?.sessionUrl
            window.open(url, "_blank")
          })
         
       
    }
    const handelForm =(e)=>{
        setFormReg({...formReg, [e.target.name]:e.target.value})
    }
    useEffect(() => {
        async function UserGet() {
          await UserGetId().then((res) => {
            setUserData(res);
      
          })
        }
        UserGet();
      }, []);
  return (
    <>
          <TopNavbar sidebarfun={sidebarfun} />
          <div className="dasboard-group" >

        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
      
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
     
                        <Row className="mb-3 align-items-center justify-content-center">
                            <div  className='card checkout-form'>
                            <h1 className="heading-dash text-dark text-center">Checkout Form</h1>
                            <hr></hr>
                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="name"
                                    maxLength={50}
                                    value={userData?.firstName}
                                    onChange={handelForm}
                                    placeholder="Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid name.
                                </Form.Control.Feedback>
                            </Form.Group>
 
                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="email"
                                    maxLength={50}
                                    value={userData?.emailId}
                                    onChange={handelForm}
                                    placeholder="Email"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid Email.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label>Customer Id</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="customerId"
                                    maxLength={50}
                                    value={state?.id}
                                    onChange={handelForm}
                                    placeholder="customerId"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid customerId.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label>Currency</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="currency"
                                    maxLength={50}
                                    value={"USD"}
                                    onChange={handelForm}
                                    placeholder="Currency"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid currency.
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="amount"
                                    maxLength={50}
                                    onChange={handelForm}
                                    placeholder="Amount"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid amount.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="validationCustom01">
                            <Form.Label>Plan Type</Form.Label>
                            <Form.Select name ="planType" onChange={handelForm} aria-label="Default select example">
                            <option>Plan Type</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                            </Form.Select>
                            </Form.Group>
                            <Form.Group  className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Discription</Form.Label>
                                <Form.Control  name="description" onChange={handelForm} as="textarea" rows={3} />
                            </Form.Group>
                            <Row>
                           <Col as ={Col} md={4}></Col>
                           <Col as={Col} md={8} className='text-right'> <Button type="submit"  className='btn-Save me-3'>Save</Button>
                               <Button type="button" className='btn-Cancel secondary'  >Cancel</Button>
                           </Col>
                           </Row>
                            </div>



                        </Row>
                       

                    </Form>
        </div>
        
        </div>
    
    </>
  )
}
