import React from 'react'
import { Button, Col, Container,Row } from 'react-bootstrap'
import * as icons from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

export const OrganizationPayment = () => {
    const navigate = useNavigate();
    const backBtn=(event)=>{
      navigate(-1);
    }
  return (
   <>
   <div className='icon-back'>
     <icons.ArrowLeft onClick={backBtn}/>
     </div>
      
   <section className='payment-plan'>
    <div>

    <div className='text-content'>
   
   <h1>Choose Your <span>Subscription</span> Plan</h1>
   <p className='para-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis gravida ante a facilisis. Nulla ultricies euismod nisi eu pretium.</p>
   </div>
   <Container>
   <Row>

    <Col md={4}>
    <div className='item-col'>
    <h3>Monthly<br/><span>$99</span> <span className='permonthtext'>per month</span></h3>
    
    <div className='text-center'>
    <Button type='button' className='btn-payment'>Upgrade now</Button>
    </div>
    </div>
    </Col>
    <Col md={4}>
     
     <div className='item-col active'>
        
     <h3>Yearly<br/><span>$1188</span> <span className='permonthtext'>per month</span></h3>
   
    <div className='text-center'>
    <Button type='button' className='btn-upgarde'>Upgrade now</Button>
    </div>
    </div>
    </Col>
    <Col md={4}>
    <div className='item-col'>
     <h3>One Time<br/><span>$8,99</span></h3>
   
    <div className='text-center'>
    <Button type='button'  className='btn-payment'>Upgrade now</Button>
    </div>
    </div>
    </Col>
   </Row>
   </Container>
   </div>
   </section>
   </>
  )
}
