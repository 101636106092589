export const SetToken = (token) => localStorage.setItem('isToken', token);
export const SetRefreshToken = (refreshToken) => {
    localStorage.setItem('isRefreshToken', refreshToken)
};
export const SetUser = (user) => localStorage.setItem("isUser", JSON.stringify(user));
export const GetToken = () => localStorage.getItem('isToken');
export const isExpired = () => localStorage.getItem('token');
export const GetRefreshToken = () => localStorage.getItem('isRefreshToken');
export const GetUser = () => localStorage.getItem('isUser');
export const RemoveToken = () => localStorage.removeItem('isToken');
export const setRoles = (role) => localStorage.setItem('role', role);
export const RemovRoles = () => localStorage.removeItem('role');
export const RemoveRefreshToken = () => localStorage.removeItem('isRefreshToken');
export const RemoveUser = () => localStorage.removeItem('userData');
