import React from 'react'
import Headertwo from './Headertwo'
import Footertwo from './Footertwo'
import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import Carousel from 'react-multi-carousel';

export const ManagerTips = () => {
    const responsive2 = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
  return (
  <>
  <div className='group-section'>
				<Headertwo />
				<div className='banner-item-Manager-tips'>
					<div>
						
					</div>
				</div>


			</div>



			<section className='p-3 about-us-page'>
				<Container>
				 <Row>
                    <Col md={12} className='text-center'>
                       <h2 className='mb-3'>Manager Tips</h2>
                       <ListGroup as="ol" numbered>
                        <ListGroup.Item  className="list-tips-item" as="li" action >Create and run small test auctions before running your real auction.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Test all the Manager features including the pause auction feature.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Have your Players test out the Secondary Market features of your test
                        auctions.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Review the Manager data collection tools to better understand the
                        power of Xcutta.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Set up a tournament bracket for your Players if your auction includes a
                        sport with tournament play.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Review the sports and teams available in Xcutta and be aware a
                        Manager can create more sports or teams within his account.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Choose the Field as a team in your auction to cover all remaining teams
in the contest not included in your auction. Each sport in Xcutta
includes a Field team. </ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Teams in your auction not receiving a bid can be declared part of the
                        Field team by the Manager</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Create multiple winning places in your contest to increase Player
                        participation and enthusiasm.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Select a Public Secondary Market to allow all Xcutta subscribers access
                        or select a Private Secondary Market to allow only your Players access. </ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Create numerous auctions from one sporting event: For example, the
64 team NCAA Basketball tournament is played in rounds. Once the
first round is complete, create a new auction for the 32 teams that
advanced to the second round.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Create Auction Contest for sports without tournament play. For
example, an auction for a Nascar or F1 Race can include all the drivers
of one race with your contest winnings declared for 1st, 2nd, 3rd, 4th
place etc. A horse race such as the Kentucky Derby can auction off all
horses in the race with your choice of winning places. </ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Customize auctions to include local sporting events such as your local
golf tournament and players. For example, create a new sport (Local
Golf Course Sport) in your account and add your local golfers as teams
of the Local Golf Course Sport. Set up your auction with each player
listed and choose your winnings.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >A Manager has the power to Add Admin for additional support with his
                        auction and player management</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Communicate with your Players often through the Manager
                        announcement portal to promote your auctions and secondary markets.</ListGroup.Item>
                        <ListGroup.Item className="list-tips-item" as="li" action >Open your mind to the possibilities of auctions for sport divisions
conferences, leagues, trophies, and cups. Consider creating multiple
auctions with different minimum bids and bid increments for each
sporting event</ListGroup.Item>
                        </ListGroup>
                    </Col>
                 </Row>
						
				</Container>
			</section>

			<Footertwo />
  </>
  )
}
