import React, { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from '../../Footer';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as icons from 'react-bootstrap-icons';
import { GetNotificationMes, NotificationReg } from "../../redux/auth-reducer/auth-service";
import { ModalBox } from "../Outer/Modal";
import { ToastContainer, toast } from 'react-toastify';

export const Admin_notification = () => {
    const [data, setData] = useState(false);
    const [notificationMes, setNotificationMes] = useState([]);
    const [showView, setShowView] = useState(false);
    const [validated, setValidated] = useState(false);
    const [count, setCount] = useState(0);
    const [itemData, setItemData] = useState([]);
    const [itemLastData, setItemLastData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationsPerPage] = useState(7);
    const [activePage, setActivePage] = useState(1);
    const [notificationReg, setNotificationReg] = useState({
        message: "",
    })
    const sidebarfun = () => {
        setData(!data)
    }
    const viewFn = (viewItem) => {
        setShowView(true);
    }

    useEffect(() => {

        const NotificationMessageFun = async () => {
            await GetNotificationMes().then((response) => {

                setNotificationMes(response?.data?.dataList);
            })
        }
        NotificationMessageFun();

    }, [count])

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = notificationMes?.slice(indexOfFirstNotification, indexOfLastNotification);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        setActivePage(pageNumber);
    }
    const totalPages = Math.ceil(notificationMes.length / notificationsPerPage);

    const handleClosed = () => {
        setShowView(false);
    }

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() == 0) {
            setValidated(true);
        }
        const registerData = {
            "message": notificationReg?.message
        }
        await NotificationReg(registerData).then((res) => {
            if (res) {

                setCount(count + 1);
                setShowView(false);
                toast.success("Success");

            }
            else {

            }
        })
    };

    const heandelChange = (e) => {
        setNotificationReg({
            ...notificationReg,
            [e.target.name]: e.target.value
        }
        );

    }


    useEffect(() => {

        notificationMes?.map((item) => {
            var user = item?.userName.split(" ");
            var dataList = user?.[0];
            var dataList2 = user?.[1];
            var listItem = dataList.charAt(0)
            var listItem2 = dataList2.charAt(0)
            setItemData(listItem)
            setItemLastData(listItem2)
        })

    }, [notificationMes])

    return (
        <>
            <ToastContainer />
            <TopNavbar sidebarfun={sidebarfun} />
            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <Row className='d-flex align-items-center'>
                        <Col md={6}><h1 className="heading-dash">Notification</h1></Col>
                        <Col md={6} className='text-right'><Button className='primary button-create-auction btn btn-primary ' onClick={() => viewFn()}><icons.Plus class="icon-plus" />  Broadcast Massage</Button></Col>

                    </Row>
                    <div className='group_data_table'>
                        <div className="notification_box">

                            {/* {notificationMes?.map((ele) =>
                                <>

                           

                                    <div className="notification pt-3">

                                        <div className="notification-right">
                                            <div className="profile-pic2">

                                            {itemData} {itemLastData}
                                         
                                            </div>
                                            <div className="ms-3">
                                                <h1>
                                                    {ele?.userName}
                                                </h1>
                                                <p>{ele?.message}
                                                    <br></br>
                                                    {ele?.createDate}
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                </>

                            )} */}

                            {currentNotifications?.map((ele) => (
                                <div className="notification pt-3" key={ele.id}>
                                    <div className="notification-right">
                                        <div className="profile-pic2">
                                            {itemData} {itemLastData}
                                        </div>
                                        <div className="ms-3">
                                            <h1>{ele?.userName}</h1>
                                            <p>
                                                {ele?.message}
                                                <br />
                                                {ele?.createDate}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* Pagination Controls */}
                            <div className="pagination notification-sec">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button className={`p-2 pagenation-item ${activePage === index + 1 ? 'active' : ''}`}  key={index + 1} onClick={() => paginate(index + 1)}>
                                        {index + 1}
                                    </button>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                <div className={data ? "Footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>

            <ModalBox show={showView} title={<><h1 className="heading-dash">Add Broadcast Massage</h1></>} body={
                <>

                    <Row>

                        <Col>

                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            required
                                            as="textarea"
                                            name="message"
                                            rows={3}
                                            onChange={heandelChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter Message.
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                </Row>
                                <Row>
                                    <Col md={6}></Col>
                                    <Col md={6} className='text-right'>

                                        <Button type="button" className='btn-Cancel secondary  me-3 mb-3' onClick={handleClosed} >Cancel</Button>
                                        <Button type="submit" className='btn-Save  mb-3'>Post</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                    </Row>

                </>
            } />
        </>
    )
}


