import { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar"
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar"
import { Footer } from "../../Footer"
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { CheckoutAddPayment, CustomerAddPayment, PaymentGetById } from "../../redux/auth-reducer/auth-service";
import dayjs from "dayjs";

export const Account_settings = () => {
    const [data, setData] = useState(false);
    const [dataItem, setDataItem] = useState();
    const [userData, setUserData] = useState();
    const sidebarfun = () => {
        setData(!data)
    }
    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser?.userId;
    const userName = isUser?.fullName;

    useEffect(() => {

        const GetByIdPayment = async () => {
            await PaymentGetById(userId).then((response) => {
                setDataItem(response?.data?.data)
                setUserData(response);
            })
        }
        GetByIdPayment();

    }, []);





    const makePayment = async () => {
        const data = {
            "name": userName,
            "email": dataItem?.email,
        }
    
        let customerIdValue;
        await CustomerAddPayment(data).then((res) => {
            if (res?.status == 200) {
                customerIdValue = res?.data?.data?.customerId;
       
                localStorage.setItem('sesstionID', res?.data?.data?.sessionId);
            
                
            } else {
              
            }
        });

        const dataCheckout = {
            "id": userId,
            "name": userName.split(" ")[0],
            "email": dataItem?.email,
            "customerId": customerIdValue,
            "currency": "USD",
            "amount": dataItem?.amount * 100,
            "planType": dataItem?.planType,
            "description": "Payment",
        }
        await CheckoutAddPayment(dataCheckout).then((res) => {
            let url = res?.data?.data?.sessionUrl;
   
            localStorage.setItem('sesstionID', res?.data?.data?.sessionId);
            
            window.open(url, "_blank")
        })


    }

    return (

        <>
            <TopNavbar sidebarfun={sidebarfun} />
            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Account Settings</h1>
                    <div className='group_data_table'>

                        <div className="subscription">
                            <h1 className="subscription_heading">About Subscription</h1>
                            <div className="subscription_box">
                                <div className="subscription_box-inner">
                                    <h4>Monthly Plan</h4>

                                    {
                                        userData == "No payment details found." ? "" : <Button varient="">Active</Button>
                                    }

                                </div>
                                <div className="subscription_box-inner pb-2 pt-2">
                                    <p>Your next payment date is July 27, 2024  </p>

                                </div>
                                <div className="subscription_box-inner">
                                    <p>Amount:</p> <p>{dataItem?.amount}</p>

                                </div>
                                <div className="subscription_box-inner">
                                    <p>Auto Renew</p> <p>No</p>

                                </div>
                                <div className="subscription_box-inner">
                                    <p>Subscription Type </p> <p>{dataItem?.planType}</p>

                                </div>
                                <div className="subscription_box-inner">
                                    <p>Subscription created On </p> <p>{dataItem?.paymentDate}</p>

                                </div>

                                <div className="subscription_box-inner-btn">
                                    {
                                        userData == "No payment details found." ? "" : <Button variant="" onClick={makePayment} >Make Payment</Button>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="table-transaction">
                            <h1 className="subscription_heading">Transaction History</h1>
                            <Table bordered hover className='table-team border'>
                                <thead>
                                    <tr>

                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Plan Type</th>
                                        <th>Transaction ID</th>
                                        <th>Transaction Date</th>
                                        <th>Transaction Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{dataItem?.name}</td>
                                        <td>{dataItem?.paymentStatus}</td>
                                        <td>{dataItem?.amount}</td>
                                        <td>{dataItem?.planType}</td>
                                        <td>{dataItem?.customerId}</td>

                                        <td>
                                            {
                                                dataItem?.name ? dayjs(dataItem?.paymentDate).format("MM/DD/YYYY") : ""
                                            }


                                        </td>
                                        <td>
                                            {
                                                dataItem?.name ? dayjs(dataItem?.paymentDate).format("HH:mm:ss") : ""

                                            }

                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </div>
                <div className={data ? "Footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}
